import { useRef, useState } from 'react'
import moment from 'moment'
import { getEpisodeDisplayItemTitle, getShowPageVodDetails } from '@dstv-web-leanback/dstv-frontend-services'

export const isElementInViewport = (el) => {
	const rect = el.getBoundingClientRect()
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	)
}

export const isElementVisible = (el) => {
	const rect = el.getBoundingClientRect()
	return rect.left <= (window.innerWidth || document.documentElement.clientWidth)
}

export const pixelsToVW = (pixels) => {
	return (pixels / 1920) * 100
}

export const getNewProfileDetails = (selectedProfile, newProfile) => {
	let alias, avatarUri, avatarId

	if (selectedProfile) {
		alias = newProfile?.alias || selectedProfile?.alias

		avatarUri = newProfile?.avatarUri || selectedProfile?.avatar?.uri || './assets/fallback-avatar.png'

		avatarId = newProfile?.avatarId || selectedProfile?.avatar?.id
	} else {
		alias = newProfile?.alias
		avatarId = newProfile?.avatarId
		avatarUri = newProfile?.avatarUri || './assets/fallback-avatar.png'
	}

	return { alias, avatarId, avatarUri }
}

export const useRefState = (initialState) => {
	const [state, setState] = useState(initialState)
	const stateRef = useRef(null)
	stateRef.current = state
	return [state, setState, stateRef]
}

export const formatTimeToHoursAndMinutes = (time) => {
	return moment(time).format('HH:mm')
}

export const getProgressBarPercent = (data) => {
	let startTime, currentTime, endTime, totalDuration, timedifference

	startTime = new Date(data?.start_date_time)
	currentTime = new Date()
	endTime = new Date(data?.end_date_time)

	if (startTime && endTime) {
		totalDuration = endTime - startTime
		timedifference = getDifferenceInSeconds(startTime, currentTime) * 1000
		return (
			!!(timedifference && totalDuration) &&
			((timedifference / totalDuration) * 100 > 100 ? 100 : (timedifference / totalDuration) * 100)
		)
	}
	return null
}

const getDifferenceInSeconds = (time1, time2) => {
	return (time2.getTime() - time1.getTime()) / 1000
}

export const getShowpageRoute = (videoId, type) => {
	let showpageRoute = null
	if (videoId) {
		showpageRoute = `/showpage/${videoId}/${type}`
	}
	return showpageRoute
}

export const getContentpageRoute = (itemId) => {
	let contentpageRoute = null
	if (itemId) {
		contentpageRoute = `/contentpage/${encodeURIComponent(itemId)}`
	}
	return contentpageRoute
}

export const convertSecondsToMins = (timeInSec) => +timeInSec && Math.floor(+timeInSec / 60)

const getTimeFromDateTime = (startDateTime) => {
	let dateTime = new Date(startDateTime)
	function formatValue(i) {
		return i < 10 ? '0' + i : i
	}
	let time = `${formatValue(dateTime.getHours())}:${formatValue(dateTime.getMinutes())}`

	return time
}

export const getTimeInDisplayFormat = (dateTime) => {
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
	let newDateTime = new Date(dateTime)

	let dayOfWeek = days[newDateTime.getDay()]
	let dayOfMonth = newDateTime.getDate()
	let month = months[newDateTime.getMonth()]
	let time = getTimeFromDateTime(newDateTime)

	return `${dayOfWeek} ${dayOfMonth} ${month} ${time}`
}
export const getThumbnailUrl = (url) => {
	let thumbnailUrl = url.replace('proxy/', '')
	let lastSlashIndex = thumbnailUrl.lastIndexOf('/')
	thumbnailUrl = thumbnailUrl.substring(0, lastSlashIndex)
	return thumbnailUrl
}

export const getLatestBookmark = (bookmarksState) => {
	const bookmarks = bookmarksState?.bookmarks
	return bookmarks?.[bookmarks.length - 1]
}

export const getLatestBookmarkedVodDetails = (currentSeasonVideos, resumeVideoId) => {
	return (
		resumeVideoId &&
		getShowPageVodDetails(
			currentSeasonVideos?.videos?.find((item) => item.external_reference.video_meta_id.split('_')[1] === resumeVideoId)
		)
	)
}

export const getLatestBookmarkedVideo = (showpageState, genref, currentSeasonId) => {
	let videoObj = null
	if (showpageState?.data?.episodeDetails?.length) {
		const currentSeasonVideos = getNextSeasonVideos(showpageState?.data?.episodeDetails, currentSeasonId)
		videoObj = currentSeasonVideos?.videos?.find(
			(item) => item.external_reference.video_meta_id.split('_')[1] === genref
		)
	} else if (showpageState?.data?.movieDetails) {
		videoObj = showpageState?.data?.movieDetails?.videos
	}
	return genref && videoObj
}

export const getNextSeasonVideos = (episodeDetails, currentSeasonId) => {
	let seasonVideos = null
	if (episodeDetails?.length) {
		seasonVideos = episodeDetails.find((item) => item?.seasonId === currentSeasonId)
	}
	return seasonVideos
}

export const getSeasonDetails = (data) => {
	const seasonNumber = data?.seasonNumber
	const episodeNumber = data?.episode
	const displayItemTitle = data?.title

	return getEpisodeDisplayItemTitle(seasonNumber, episodeNumber, displayItemTitle)
}

export const getBookmarkDetails = (bookmarksState, showpageState, currentSeasonId) => {
	const latestBookmark = getLatestBookmark(bookmarksState)
	const bookmarkedVideo = getLatestBookmarkedVideo(showpageState, latestBookmark?.genref, currentSeasonId)

	const displayItemDetailedTitle = bookmarkedVideo?.displayItemDetailedTitle || getSeasonDetails(bookmarkedVideo)

	return displayItemDetailedTitle
}

export const getVisibleCardCount = (type) => {
	switch (type) {
		case 'vod_with_progress':
			return 5
		case 'big16x9':
			return 3
		case 'small16x9':
			return 5
		case 'poster3x4':
			return 9
		case 'posternumbered':
			return 9
		case 'posterleadtitle':
			return 9
		case 'episodecard16x9':
			return 5
		case 'containedcard':
			return 5
		case 'expandablecard16x9':
			return 9
		case 'contentpage':
			return 5
		case 'livecard16x9':
			return 5
		case 'midrailbillboard':
			return 2
		case 'singleeventbillboard':
			return 1
		default:
			return 9
	}
}

export const navigateToShowPage = (item, location, navigate, getVideoId) => {
	const pageType = location.pathname.replace('/', '')
	const isCatchUp = ['tvshows', 'sport', 'kids', 'movies', 'channelgroups', 'mystuff'].includes(pageType)
	const videoId = !isCatchUp ? item.id : getVideoId(item)
	const showpageRoute = getShowpageRoute(videoId, item.type)
	showpageRoute && navigate(showpageRoute)
}

export const getTimePassedInSeconds = (startTime) => {
	let durationInSeconds = 0
	if (startTime !== null && startTime !== undefined) {
		let endTime = new Date()
		const timeDifference = endTime.getTime() - startTime.getTime()
		durationInSeconds = timeDifference / 1000
	}
	return durationInSeconds
}

export const navigateToContentPage = (item, location, navigate, itemId) => {
	const contentpageRoute = getContentpageRoute(item.id)
	contentpageRoute && navigate(contentpageRoute)
}

export const getPlayerConfiguration = (sessionId, type, contentDetails, config) => {
	let abrConfig = config?.shaka_abr_config?.payload
	let retryConfig = config?.shaka_retry_config?.payload
	let { variant, ...streamingConfig } = config?.enable_cv_player?.payload || {}

	return {
		abr: { ...prepareConfigObject(abrConfig) },
		drm: {
			servers: {
				'com.widevine.alpha': `https://licensev2.dstv.com/widevine/getLicense?CrmId=afl&AccountId=afl&ContentId=${contentDetails.contentId}&ls_session=${sessionId}`,
			},
			advanced: {
				'com.widevine.alpha': {
					audioRobustness: 'SW_SECURE_CRYPTO',
					videoRobustness: 'SW_SECURE_CRYPTO',
					persistentStateRequired: Boolean(process.env.NX_PERSISTENT_STATE_REQUIRED) || false,
				},
			},
		},
		streaming: {
			...(type === 'live' && config?.shaka_low_latency_enabled?.flagValue === 'true'
				? { lowLatencyMode: true }
				: prepareConfigObject(streamingConfig)),
			retryParameters: {
				...prepareConfigObject(retryConfig),
			},
		},
	}
}

export const getStreamUrl = (
	type,
	manifestUrl,
	ucp_filter,
	cdnAuthToken,
	ssaiQueryParam,
	liveWatchfromStart,
	config
) => {
	const hdcpFilters = ucp_filter.split('?')[1]

	let pulseAdsSSAIStr = ''

	if (config?.ssai_enabled.flagValue === 'true') {
		pulseAdsSSAIStr = ssaiQueryParam ? `ssai=${ssaiQueryParam}&` : ''
	}

	const watermarkingToken = encodeURIComponent(cdnAuthToken)

	let playerUrl = manifestUrl?.split('?')[0] + '/.mpd?'
	const restParams = manifestUrl?.split('?')[1]
	if (type === 'live') {
		if (liveWatchfromStart) return manifestUrl + '&' + hdcpFilters + '&hdnts=' + watermarkingToken
		playerUrl = playerUrl + pulseAdsSSAIStr + hdcpFilters + '&hdnts=' + watermarkingToken
	} else {
		playerUrl = playerUrl + hdcpFilters
	}
	return playerUrl + (restParams ? '&' + restParams : '')
}

const prepareConfigObject = (originalConfig) => {
	let parsedConfig = {}

	for (let key in originalConfig) {
		try {
			parsedConfig[key] = originalConfig[key].includes('.')
				? parseFloat(originalConfig[key])
				: parseInt(originalConfig[key])
		} catch (err) {
			parsedConfig[key] = null
		}
	}

	return parsedConfig
}

export const isLiveEventCard = (cardType) => {
	const validCardTypes = ['singleeventbillboard', 'midrailbillboard', 'livecard16x9', 'channels']
	return validCardTypes.includes(cardType)
}
