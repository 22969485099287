//import { getSmartTvResolution } from '../Utils/platform'
// Platform Config.
export const ENV_MODE = process.env.NODE_ENV
export const DEV_MODE = ENV_MODE === 'development'
export const shouldBenchmark = process.env.NX_ENABLE_PERFORMANCE_METRICS === '2'
export const productId = process.env.NX_PLATFORM_ID || '1b09957b-27aa-493b-a7c9-53b3cec92d63'
export const packageId = process.env.NX_PACKAGE_ID || '3e6e5480-8b8a-4fd5-9721-470c895f91e2'
export const BITMOVIN_ANALYTICS_KEY = process.env.NX_BITMOVIN_ANALYTICS_KEY || 'fade2fe8-0e20-4996-9f15-0bac4dcca32c'
export const BITMOVIN_DOMAIN = process.env.NX_BITMOVIN_DOMAIN || 'dstv.com'
export const PUSHER_KEY = process.env.NX_API_ENVIRONMENT === 'ALPHA' ? '84a02c83d1c3f5d29a24' : '5b1cf858986ab7d6a9d7'
export const SECRET_KEY = process.env.NX_SECRET_KEY || '@#$2345678@#$%^'

export const EXPLORA_TEMP_PLATFORM_ID =
	process.env.NX_EXPLORA_TEMP_PLATFORM_ID || '32faad53-5e7b-4cc0-9f33-000092e85950'
export const BOOKMARK_THRESHOLD = process.env.NX_BOOKMARK_THRESHOLD || 93
export const DATA_DOG_APPLICATION_ID = process.env.NX_DATA_DOG_APPLICATION_ID || '353b47ac-c44e-4981-8f34-14d05b178101'
export const DATA_DOG_CLIENT_TOKEN = process.env.NX_DATA_DOG_CLIENT_TOKEN || 'pub731233ceb3adec46e059b68e97347352'
export const DATA_DOG_SITE = process.env.NX_DATA_DOG_SITE || 'datadoghq.eu'
export const DATA_DOG_SERVICE = process.env.NX_DATA_DOG_SERVICE || 'leanback-2.0-perfomance'
export const DATA_DOG_ENV = /staging|localhost|sit|suitest/.test(window.location.hostname) ? 'dev' : 'prod'
export const DATA_DOG_SAMP_RATE = process.env.NX_DATA_DOG_SAMP_RATE || 20
export const DATA_DOG_REPLAY_SAMP_RATE = process.env.NX_DATA_DOG_REPLAY_SAMP_RATE || 5

export const DATADOG_CONFIG = {
	applicationId: DATA_DOG_APPLICATION_ID,
	clientToken: DATA_DOG_CLIENT_TOKEN,
	site: DATA_DOG_SITE,
	service: DATA_DOG_SERVICE,
	env: DATA_DOG_ENV,
	version: process.env.APP_VERSION,
	sessionSampleRate: DATA_DOG_SAMP_RATE,
	sessionReplaySampleRate: DATA_DOG_REPLAY_SAMP_RATE,
}
const getPlatformId = () => {
	switch (window.deployment_platform) {
		case 'explora':
			return '7228971d-040d-4eea-bd9c-d1b241eca011'
		case 'smarttv':
			return window.deployment_device_type === 'Xbox'
				? '7228971d-040d-4eea-bd9c-d1b241eca011'
				: 'f8113a08-286b-4250-b7c5-31fbfcaec8b0'
		case 'streama':
			return '7869f714-c674-4386-8a05-8e37b619bb68'
		case 'chromecast':
			return 'f8113a08-286b-4250-b7c5-31fbfcaec8b0'
		default:
			return '32faad53-5e7b-4cc0-9f33-000092e85950'
	}
}

const getDeviceType = () => {
	switch (window.deployment_platform) {
		case 'smarttv':
			return 'deviceType=Smart TV'
		case 'streama':
			return 'deviceType=Streama MDMP100'
		default:
			return 'deviceType=Web'
	}
}

export const getSegmentId = () => {
	switch (window.deployment_environment) {
		case 'STAGING_LG':
		case 'STAGING_WEBOS':
		case 'STAGING_WEB0S':
			return 'iU2CX5HyDDo7xecP5crN8nBtFytTLkFV'
		case 'STAGING_HISENSE':
			return 'jxdcJn0jpAfaCFzTXpl3Bdp9dgdPdNBY'
		case 'STAGING_XBOX':
			return '9bOBY8Uy5O0ZCftsZNiVoZMo7dWXj5gZ'
		case 'STAGING_SAMSUNG':
		case 'STAGING_TIZEN':
			return '0wIybhrtrZ7kqLeRtArBM1FZHMkTLtyy'
		case 'STAGING_EXPLORA':
			return 'swG7uAPNZ2bskRxNp4lnbFFw9EgxEKvn'
		case 'STAGING_STREAMA':
			return 'COsDGEyin0YLUTslo9PAE3hJphYSE9Y1'

		case 'PRODUCTION_LG':
		case 'PRODUCTION_WEBOS':
		case 'PRODUCTION_WEB0S':
			return '5uemYAdReviLedFySMovOh8e5wvPBg6F'
		case 'PRODUCTION_HISENSE':
			return '1aX5V30fzduMMYP7Cw6PgCaYSF9aR1BZ'
		case 'PRODUCTION_XBOX':
			return 'q7cNYGLyTsacyBdtYhPWYAo9TF1lwBNz'
		case 'PRODUCTION_SAMSUNG':
		case 'PRODUCTION_TIZEN':
			return '0wIybhrtrZ7kqLeRtArBM1FZHMkTLtyy'
		case 'PRODUCTION_EXPLORA':
			return 'XJAkdSfFjBzBOOa4BG7OAhU2Z9SFcF2z'
		case 'PRODUCTION_STREAMA':
			return '8CW2rBqQt8YiC0pkb49MIrSbz6ZSmcxO'

		case 'PRODUCTION':
			return 'HQYDJg6nVSloaf5746neLAPozIolCPew'
		default:
			return 'juaj5IYNp5WYlmViyTIVeLsewuuEvgXf'
	}
}

export const segmentId = getSegmentId()
export const platformId = getPlatformId()
export const DEVICE_TYPE = getDeviceType()

export const exploraTempPlatformId = '32faad53-5e7b-4cc0-9f33-000092e85950'

export const CONFIG = {
	PRODUCT_ID: `productId=${productId}`,
	PLATFORM_ID: `platformId=${platformId}`,
	PLATFORM_ID_MENU: `platform_id=${platformId}`,
	PACKAGE_ID: `packageId=${packageId}`,
	EXPLORA_PLATFORM_ID: `platformId=${exploraTempPlatformId}`,
}

export const PAGE_SIZE = `page_size=${window.deployment_platform === 'smarttv' ? 50 : 30}`
const PRODUCT_PLATFORM_PACKAGE_ID = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID};${CONFIG.PACKAGE_ID}`
const PRODUCT_PLATFORM_ID_DEVICE_TYPE = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID};${DEVICE_TYPE}`
export const PRODUCT_PLATFORM_ID = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID};`

// cs-mobile Version.
export const API_VERSION = process.env.NX_API_VERSION || 'v7'

let key = {
	VERSION: 'version',
	ENVIRONMENT: 'env',
}

const getEnvironment = () => {
	let environment = switchVersionEnv(key.ENVIRONMENT)
	if (!environment) {
		switch (process.env.NX_API_ENVIRONMENT) {
			case 'ALPHA':
				environment = 'ALPHA'
				break
			case 'BETA':
				environment = 'BETA'
				break
			case 'PRODUCTION':
				environment = 'PROD'
				break
			default:
				environment = 'PROD'
		}
		return environment
	}
}

const switchVersionEnv = (key) => {
	let querys = window.location.search.split('&')

	let versionFilter = querys.filter((e) => {
		return e.indexOf(key) !== -1
	})
	if (versionFilter.length > 0) {
		let valueSplit = versionFilter[0].split('=')
		console.log(valueSplit)
		return valueSplit[1]
	}
	return ''
}

export const BASE_API_END_POINT = {
	ALPHA: 'https://alpha-ssl.dstv.com/api',
	BETA: 'https://beta-ssl.dstv.com/api',
	PROD: 'https://ssl.dstv.com/api',
}

export const BASE_URL = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/cs-mobile/',
	BETA: 'https://beta-ssl.dstv.com/api/cs-mobile/',
	PROD: 'https://ssl.dstv.com/api/cs-mobile/',
}

export const GET_ALL_CHANNELS = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/events;genre=ALL;country={COUNTRY_CODE};packageId={PACKAGE};count=2;utcOffset=+02:00`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/events;genre=ALL;country={COUNTRY_CODE};packageId={PACKAGE};count=2;utcOffset=+02:00`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/events;genre=ALL;country={COUNTRY_CODE};packageId={PACKAGE};count=2;utcOffset=+02:00`,
}

export const GET_DAY_EVENTS = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/epgEvents/byDate;channelTag={CHANNEL_TAG};date={DATE}`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/epgEvents/byDate;channelTag={CHANNEL_TAG};date={DATE}`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/epgEvents/byDate;channelTag={CHANNEL_TAG};date={DATE}`,
}

export const GET_EVENT_BY_ID = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/v7/epg-service/event;eventId={EVENT_ID}`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/v7/epg-service/event;eventId={EVENT_ID}`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/v7/epg-service/event;eventId={EVENT_ID}`,
}

export const GET_MENU = {
	ALPHA: `https://alpha-ssl.dstv.com/api/navigation_menu?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}&${CONFIG.PLATFORM_ID_MENU}&revision=12`,
	BETA: `https://ssl.dstv.com/api/navigation_menu?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}&${CONFIG.PLATFORM_ID_MENU}&revision=12`,
	PROD: `https://ssl.dstv.com/api/navigation_menu?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}&${CONFIG.PLATFORM_ID_MENU}&revision=12`,
}

export const GET_PROGRAM = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
}

export const GET_STACK_LOOKUP = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/vod/granular_catalogue/deprecated/stack_lookup/{TYPE}/{ID}?country={COUNTRY_CODE}&subscription_package={PACKAGE}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/vod/granular_catalogue/deprecated/stack_lookup/{TYPE}/{ID}?country={COUNTRY_CODE}&subscription_package={PACKAGE}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/vod/granular_catalogue/deprecated/stack_lookup/{TYPE}/{ID}?country={COUNTRY_CODE}&subscription_package={PACKAGE}`,
}

export const AUTO_PLAY_NEXT_EPISODE = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/dstv_now/continue_watching/videos/{id}/auto_play',
	BETA: 'https://beta-ssl.dstv.com/api/dstv_now/continue_watching/videos/{id}/auto_play',
	PROD: 'https://ssl.dstv.com/api/dstv_now/continue_watching/videos/{id}/auto_play',
}

export const UPDATE_WATCHBUTTON_API = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/dstv_now/continue_watching/{type}/{id}/info',
	BETA: 'https://beta-ssl.dstv.com/api/dstv_now/continue_watching/{type}/{id}/info',
	PROD: 'https://ssl.dstv.com/api/dstv_now/continue_watching/{type}/{id}/info',
}

export const DEVICE_REGISTRATION = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/lean-back-otp/device/registration',
	BETA: 'https://beta-ssl.dstv.com/api/lean-back-otp/device/registration',
	PROD: 'https://ssl.dstv.com/api/lean-back-otp/device/registration',
}

export const REFRESH_TOKEN_API = {
	ALPHA: 'https://alpha-ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3',
	BETA: 'https://alpha-ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3',
	PROD: 'https://ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3',
}

export const GET_USER_INFO = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/user/info',
	BETA: 'https://beta-ssl.dstv.com/api/user/info',
	PROD: 'https://ssl.dstv.com/api/user/info',
}

export const GET_PROXIMITY_STATUS = {
	ALPHA: 'https://alpha-ssl.dstv.com/proximity',
	BETA: 'hhttps://beta-ssl.dstv.com/proximity/status',
	PROD: 'https://ssl.dstv.com/proximity/status',
}

export const GET_SEARCH_LANDING_CONTENT = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/pages/v2/general/search_landing?${CONFIG.PLATFORM_ID_MENU}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/pages/v2/general/search_landing?${CONFIG.PLATFORM_ID_MENU}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/pages/v2/general/search_landing?${CONFIG.PLATFORM_ID_MENU}`,
}

export const GET_SEARCH = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/search/v2?${CONFIG.PLATFORM_ID_MENU}&search_term={SEARCH_TERM}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/search/v2?${CONFIG.PLATFORM_ID_MENU}&search_term={SEARCH_TERM}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/search/v2?${CONFIG.PLATFORM_ID_MENU}&search_term={SEARCH_TERM}`,
}

export const GET_SEARCH_SUGGESTIONS = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/search/v2/suggestions?${CONFIG.PLATFORM_ID_MENU}&search_term={SEARCH_TERM}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/search/v2/suggestions?${CONFIG.PLATFORM_ID_MENU}&search_term={SEARCH_TERM}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/search/v2/suggestions?${CONFIG.PLATFORM_ID_MENU}&search_term={SEARCH_TERM}`,
}

export const GET_APPLIST = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/apps`,
	BETA: ` ${BASE_API_END_POINT.BETA}/apps`,
	PROD: `${BASE_API_END_POINT.PROD}/apps`,
}

export const GET_ENTITLEMENT = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/vod-auth/entitlement/session',
	BETA: 'https://beta-ssl.dstv.com/api/vod-auth/entitlement/session',
	PROD: 'https://ssl.dstv.com/api/vod-auth/entitlement/session',
}

export const GET_CDN_ACCESS_TOKEN = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/play_stream/access_token?channel_tag={channel_tag}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/play_stream/access_token?channel_tag={channel_tag}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/play_stream/access_token?channel_tag={channel_tag}`,
}

export const GET_AD_REQUEST_API_URL = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/channels/{channel_tag}/events?number_of_events={num_of_events}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/channels/{channel_tag}/events?number_of_events={num_of_events}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/channels/{channel_tag}/events?number_of_events={num_of_events}`,
}

export const TERMS_AND_CONDITIONS = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/terms_and_conditions`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/terms_and_conditions`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/terms_and_conditions`,
}

//Environment Variables.
export const ENV = {
	BASE_URL: BASE_URL[getEnvironment()],
	BASE_API_END_POINT: BASE_API_END_POINT[getEnvironment()],
	DELETE_DEVICE: `user-manager/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/deregisterDevice;videoPackageId=3e6e5480-8b8a-4fd5-9721-470c895f91e2;${CONFIG.PRODUCT_ID};deviceId=`,
	DEVICE_REGISTRATION: DEVICE_REGISTRATION[getEnvironment()],
	GET_ALL_DEVICES: `user-manager/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/getDevices;videoPackageId=3e6e5480-8b8a-4fd5-9721-470c895f91e2;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};`,
	GET_MENU: GET_MENU[getEnvironment()],
	GET_ALL_CHANNELS: GET_ALL_CHANNELS[getEnvironment()],
	GET_DAY_EVENTS: GET_DAY_EVENTS[getEnvironment()],
	GET_EVENT_BY_ID: GET_EVENT_BY_ID[getEnvironment()],
	GET_PROFILES_LIST: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles`,
	REFRESH_TOKEN_API: REFRESH_TOKEN_API[getEnvironment()],
	REGISTER_DEVICE: `user-manager/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/vod-authorisation;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};deviceId=`,
	GET_USER_INFO: GET_USER_INFO[getEnvironment()],
	GET_PROXIMITY_STATUS: GET_PROXIMITY_STATUS[getEnvironment()],
	REMOTE_CONFIGS: 'https://features.dstv.com/api/v1/remote_configs',
	GET_SEARCH: GET_SEARCH[getEnvironment()],
	GET_SEARCH_LANDING_CONTENT: GET_SEARCH_LANDING_CONTENT[getEnvironment()],
	GET_SEARCH_SUGGESTIONS: GET_SEARCH_SUGGESTIONS[getEnvironment()],
	GET_APPLIST: GET_APPLIST[getEnvironment()],
	GET_ENTITLEMENT: GET_ENTITLEMENT[getEnvironment()],
	GET_CDN_ACCESS_TOKEN: GET_CDN_ACCESS_TOKEN[getEnvironment()],
	GET_STACK_LOOKUP: GET_STACK_LOOKUP[getEnvironment()],
	GET_VIDEO: `now-content/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};videoId={VIDEO_ID}`,
	GET_PROGRAM: `now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
	GET_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getBookmark;genref={GENREF}`,
	GET_BOOKMARKS: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/bookmarks;genref={GENREF}`,
	GET_ALL_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getAllBookmarks`,
	SAVE_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/saveBookmark`,
	GET_TRY_THIS: `editorial/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/getEditorialsForItem;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};videoId={VIDEO_ID}`,
	CREATE_PROFILE: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles`,
	UPDATE_OR_DELETE_PROFILE: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles/{PROFILE_ID}`,
	GET_AVATARS_LIST: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles/avatars`,
	WIDEVINE: 'https://licensev2.dstv.com/widevine/getLicense?CrmId=afl&AccountId=afl',
	WATCH_LIST: `${switchVersionEnv(key.VERSION) || API_VERSION}/watchlist;${PRODUCT_PLATFORM_ID_DEVICE_TYPE}`,
	AUTO_PLAY_NEXT_EPISODE: AUTO_PLAY_NEXT_EPISODE[getEnvironment()],
	GET_AD_REQUEST_API_URL: GET_AD_REQUEST_API_URL[getEnvironment()],
	UPDATE_WATCHBUTTON_API: UPDATE_WATCHBUTTON_API[getEnvironment()],
	TERMS_AND_CONDITIONS: TERMS_AND_CONDITIONS[getEnvironment()],
	STOP_STREAM: `https://licensev2.dstv.com/licenseServer/streaming/v1/afl/streams/{streamId}`,
}
