import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	getThumbnailUrl,
	PlayerButton,
	PlayerProgressBar,
	PlayerSettingsModal,
	PlayerThumbnail,
	AutoPlayNextPopup,
	Recommendations,
	TimeWidget,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	GET_NEXT_EPISODE,
	GET_TRY_THIS,
	SAVE_AUDIO_LANGUAGE,
	SAVE_BANDWIDTH,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

// Image assets
import iconCog from '../../../../assets/images/icons/icon-player-cog.svg'
import iconPause from '../../../../assets/images/icons/icon-player-pause.svg'
import iconPlay from '../../../../assets/images/icons/icon-player-play.svg'
import iconRestart from '../../../../assets/images/icons/icon-watch-from-start.svg'

import styles from '../PlayerControls.module.scss'
import { getDefaultSubOptionsValues, getPlayerSettingsOptions, convertSecondsToTime } from './helper'
import { restartLabel } from '@dstv-web-leanback/dstv-frontend-components'
import { useCreditOverlay } from '../hooks'
import VODSkipControls from './VODSkipControls'
import moment from 'moment'

export function VODControls({
	audioLanguages,
	currentTime,
	handleAudioChange,
	handleBandwidthChange,
	handleSubOptionChange,
	pause,
	play,
	playing,
	playNextEpisode,
	playPause,
	seekTo,
	video,
	videoDetails,
	stopStream,
}) {
	// Variables
	const CONTROLS_TIMEOUT = 7000
	const [controlsVisible, setControlsVisible] = useState(true)
	const [showPlayerSettings, setShowPlayerSettings] = useState(false)

	const options = useRef([])

	// Seeking
	const [seekAmount, setSeekAmount] = useState(0)
	const [seeking, setSeeking] = useState(false)
	const [seekTime, setSeekTime] = useState(0)
	const [time, setTime] = useState(moment())
	const SEEK_INTERVAL = 10
	let seekTimer = useRef()

	// Thumbnail
	const [thumbnailImage, setThumbnailImage] = useState(null)
	const [thumbnailCount, setThumbnailCount] = useState(0)
	const [thumbnailColumnCount, setThumbnailColumnCount] = useState(null)

	const dispatch = useDispatch()

	const configState = useSelector((state) => state.config)
	const playerState = useSelector((state) => state.player)
	const adPlaying = useSelector((state) => state.adPlayer.playing)
	const autoplayState = useSelector((state) => state.autoplay)

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'VOD_CONTROLS',
		forceFocus: true,
		isFocusBoundary: true,
		preferredChildFocusKey: 'PLAY_PAUSE',
		onBackPress: () => {
			hideControls()
		},
	})

	const debounce = useDebounce(CONTROLS_TIMEOUT, showControls, hideControls)
	const { showCreditOverlay, autoplayEnabled, remainingDuration, onDismiss } = useCreditOverlay(
		playNextEpisode,
		currentTime,
		videoDetails,
		video.duration,
		getTryThis
	)

	const playerControls = [
		{
			focusKey: 'PLAY_PAUSE',
			icon: playing ? iconPause : iconPlay,
			onSelect: () => {
				playPause()
			},
		},
	]

	// Effects
	useEffect(() => {
		focusSelf()
		if (!autoplayState?.data?.id || !playerState?.details?.genRef) {
			getThumbnailData()
			getNextEpisode()

			options.current = getPlayerSettingsOptions(configState, audioLanguages)
			if (!playerState.savedAudioLanguage && !playerState.savedBandwidth) {
				let defaultLanguage = audioLanguages?.[0]?.language
				const defaultBandwidth = +localStorage.getItem('QUALITY_STORAGE_BITRATE') || 6000000

				dispatch(SAVE_AUDIO_LANGUAGE({ language: defaultLanguage }))
				dispatch(SAVE_BANDWIDTH({ bandwidth: defaultBandwidth }))

				handleAudioChange(defaultLanguage)
				handleBandwidthChange(defaultBandwidth)
			}
		}
		debounce()
	}, [])

	useEffect(() => {
		if (!autoplayEnabled && playerState.tryThis?.length > 0 && showCreditOverlay) {
			pause()
			setFocus('PLAYER_RECOMMENDATIONS')
		}
	}, [playerState.tryThis, showCreditOverlay, autoplayEnabled])

	// Find the amount of columns in the thumbnail image
	useEffect(() => {
		if (thumbnailImage) {
			let thumbnailImg = new Image()
			thumbnailImg.src = thumbnailImage
			thumbnailImg.onload = () => {
				setThumbnailColumnCount(thumbnailImg.width / 150)
			}
		}
	}, [thumbnailImage])

	// When the controls become visible, set focus to the PLAY_PAUSE button
	useEffect(() => {
		if (controlsVisible) {
			setFocus('PLAY_PAUSE')
		}
	}, [controlsVisible])

	useEffect(() => {
		if (!adPlaying) {
			setFocus('PLAY_PAUSE')
		}

		return () => {
			setShowPlayerSettings(false)
		}
	}, [])

	useEffect(() => {
		const { state } = adPlaying
		if (!state) debounce()
	}, [adPlaying])

	// Functions
	const onCogBtnSelect = () => {
		setShowPlayerSettings(!showPlayerSettings)
	}

	const onRestart = () => {
		seekTo(0)
		setFocus('PLAY_PAUSE')
	}

	const getPlayerProgress = () => {
		return (currentTime / video.duration) * 100
	}

	const getSeekProgress = () => {
		return (seekTime / video.duration) * 100
	}

	const getThumbnailData = () => {
		fetch(getThumbnailUrl(playerState?.url) + '/thumbs.vtt')
			.then((response) => response.text())
			.then((text) => {
				let textArray = text.split('\n').reverse()
				for (let i = 0; i < textArray.length; i++) {
					if (textArray[i].startsWith('Img')) {
						setThumbnailCount(textArray[i].split(' ')[1])
						break
					}
					if (textArray[i].includes('#')) {
						let imagePath = textArray[i].split('#')[0]
						setThumbnailImage(getThumbnailUrl(playerState?.url) + '/' + imagePath)
					}
				}
			})
	}

	const getNextEpisode = () => {
		if (playerState?.details?.resumeVideoId) {
			dispatch(GET_NEXT_EPISODE({ id: playerState?.details?.resumeVideoId }))
		}
	}

	const handleSeek = (direction) => {
		debounce()
		setSeeking(true)

		const amount = direction === 'right' ? seekAmount + SEEK_INTERVAL : seekAmount - SEEK_INTERVAL
		setSeekAmount(amount)
		let newSeekTime = video.currentTime + amount
		if (newSeekTime < 0) {
			newSeekTime = 0
		} else if (newSeekTime > video.duration) {
			newSeekTime = video.duration
		}
		setSeekTime(newSeekTime)

		clearTimeout(seekTimer.current)
		seekTimer.current = setTimeout(() => {
			seekTo(video.currentTime + amount)
			setSeekAmount(0)
			setSeeking(false)
		}, 500)
	}

	function showControls() {
		setControlsVisible(true)
		setTime(moment())
	}

	function hideControls() {
		setControlsVisible(false)
	}

	function getTryThis(autoplayEnabled) {
		if (!autoplayEnabled) {
			dispatch(GET_TRY_THIS({ videoId: playerState.details?.id }))
		}
	}

	const handleRecommendationsCancel = () => {
		play()
		onDismiss()
	}

	if (showCreditOverlay && autoplayEnabled) {
		return (
			<AutoPlayNextPopup
				playNextEpisode={playNextEpisode}
				cancel={() => {
					onDismiss()
					setFocus('PLAY_PAUSE')
				}}
				countDownCounter={remainingDuration}
				displayHeading={autoplayState?.data?.title}
				displayEpisode={autoplayState?.data?.seasonEpisode}
			/>
		)
	}

	if (showCreditOverlay && playerState.tryThis?.length > 0) {
		return <Recommendations rows={playerState.tryThis} cancel={handleRecommendationsCancel} />
	}

	if (!controlsVisible && !showPlayerSettings) {
		return <VODSkipControls video={video} seekTo={seekTo} showControls={debounce} stopStream={stopStream} />
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div
				ref={ref}
				className={`${styles.vod_controls_wrapper} ${(controlsVisible || showPlayerSettings) && styles.visible}`}
			>
				{controlsVisible && (
					<>
						<div className={styles.time_widget_wrapper}>
							<TimeWidget currentTime={time} player={true} />
						</div>
						<div className={styles.player_controls}>
							{seeking ? (
								<PlayerThumbnail
									thumbnailCount={thumbnailCount}
									thumbnailImage={thumbnailImage}
									currentTime={seekTime}
									duration={video.duration}
									position={getSeekProgress()}
									time={convertSecondsToTime(seekTime)}
									columnCount={thumbnailColumnCount}
								/>
							) : (
								<div className={styles.metadata_wrapper}>
									<h1>{playerState?.details?.title}</h1>
									<h3>{playerState?.details?.episode_title}</h3>
								</div>
							)}
							<div className={styles.times_wrapper}>
								<div className={styles.times_left}>{convertSecondsToTime(seeking ? seekTime : video.currentTime)}</div>
								<div className={styles.times_right}>
									{convertSecondsToTime(seeking ? video.duration - seekTime : video.duration - video.currentTime)}
								</div>
							</div>
							<div className={styles.progress_wrapper}>
								<PlayerProgressBar
									focusable={true}
									handleSeek={handleSeek}
									progress={seeking ? getSeekProgress() : getPlayerProgress()}
									seeking={seeking}
								/>
							</div>
							<div className={styles.controls_wrapper}>
								<div className={styles.controls_left}>
									<PlayerButton icon={iconCog} onSelect={onCogBtnSelect} selected={showPlayerSettings} />
								</div>
								<div className={styles.controls_center}>
									{playerControls.map((button, index) => {
										return (
											<PlayerButton
												focusKey={button.focusKey}
												icon={button.icon}
												key={index}
												onSelect={button.onSelect}
											/>
										)
									})}
								</div>
								<div className={styles.controls_right_vod}>
									<PlayerButton icon={iconRestart} label={restartLabel} onSelect={onRestart} round={false} />
								</div>
							</div>
						</div>
					</>
				)}
				{showPlayerSettings && (
					<PlayerSettingsModal
						options={options.current}
						defaultValues={getDefaultSubOptionsValues(
							options.current,
							playerState.savedAudioLanguage,
							playerState?.savedBandwidth
						)}
						handleBackButton={() => setShowPlayerSettings(null)}
						handleSubOptionSelection={(optionHeading, subOption) => {
							setShowPlayerSettings(false)
							setControlsVisible(false)
							handleSubOptionChange(optionHeading, subOption)
						}}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
}
export default VODControls
