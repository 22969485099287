import moment from 'moment'

export const getCardTypeByFeatures = (features, type) => {
	if (typeof features !== 'undefined') {
		if (features.includes('small_16x9') && type === 'vod_with_progress') {
			return 'vod_with_progress'
		} else if (features.includes('big_16x9') && features.includes('trailer') && features.includes('play_in_card')) {
			return 'big16x9'
		} else if (
			features.includes('small_16x9') &&
			features.includes('trailer') &&
			features.includes('play_in_billboard')
		) {
			return 'small16x9'
		} else if (features.includes('poster') && features.includes('trailer') && features.includes('play_in_billboard')) {
			return 'poster3x4'
		} else if (features.includes('poster') && features.includes('numbered')) {
			return 'posternumbered'
		} else if (type === 'vod_with_layout' && features.includes('poster')) {
			return 'posterleadtitle'
		} else if (type === 'vod_with_playback' && features.includes('small_16x9')) {
			return 'episodecard16x9'
		} else if (features.includes('contained')) {
			return 'containedcard'
		} else if (features.includes('poster') && features.includes('trailer') && features.includes('play_in_card')) {
			return 'expandablecard16x9'
		} else if (type.includes('with_category') || (type === 'layouts' && features.includes('small_16x9'))) {
			return 'contentpage'
		} else if ((type === 'events' || type === 'event') && features.includes('small_16x9')) {
			return 'livecard16x9'
		} else if ((type === 'events' || type === 'channels' || type === 'event') && features.includes('big_landscape')) {
			return 'midrailbillboard'
		} else if (type === 'billboard' && features.includes('hero') && features.includes('trailer')) {
			return 'singleeventbillboard'
		} else {
			return type
		}
	} else {
		return type
	}
}

export const channelLive = (item) => {
	const startTime = item?.event_start_date_time || item?.start_date_time
	const endTime = item?.event_end_date_time || item?.end_date_time
	const currentTime = moment()

	if (currentTime.isAfter(endTime)) {
		return [false, 'expired', 'Expired']
	} else if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
		return [true, 'now_on']
	} else if (currentTime.isBefore(startTime)) {
		return [false, 'coming_up', 'Coming Up']
	} else {
		return []
	}
}
