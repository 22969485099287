/* eslint-disable no-fallthrough */
import { v4 as uuidv4 } from 'uuid'
import date from 'date-and-time'
import { secureSessionStorage, secureLocalStorage } from '../Utils/secureStorage'
import {
	EDITORIALS,
	GET_BILLBOARD_DATA,
	GET_BOOKMARKS,
	GET_CATCHUP_SUCCESS,
	GET_CHANNELS_SUCCESS,
	GET_CONTENT_SUCCESS,
	LOGOUT,
	SEARCH_SUCCESS,
	PLAY,
	PLAYER_ERROR,
	getIn,
	findInObject,
	WATCH_LIST_SUCCESS,
	menuDisplayName,
	getUserPackage,
} from '@dstv-web-leanback/dstv-frontend-services'

function getFocusedChildIndex() {
	return null
}

export const getProfileId = () => {
	return getFromStorage(secureSessionStorage, 'x-profile-id') || getFromStorage(secureLocalStorage, 'x-profile-id')
}

function getFromStorage(store, item) {
	const val = store.getItem(item)
	if (!val || val === 'undefined' || val === 'null') {
		return ''
	} else {
		return val
	}
}

export const getPageData = ({ type, payload = {}, ...action }, prevState) => {
	var data = {
		device: getDeviceType(),
		device_detail: getDeviceDetail(),
		product: 'DStv',
		path: window.location.href,
		referrer: '',
		title: 'DStv',
		login_status: !!secureLocalStorage.getItem('sessionID'),
		device_portfolio: getDevicePortfolio(),
		hostname: window.location.hostname,
	}
	var pageName = ''
	let prevPage = localStorage.getItem('PreviousPage')
	let content_discovery_referrer = payload.content_discovery_referrer || prevPage
	switch (type) {
		case EDITORIALS.type:
			if (!action.scheduler) {
				pageName = 'Video Detail'
				data.content_discovery_referrer = content_discovery_referrer
			}
			break
		case GET_CHANNELS_SUCCESS.type:
			pageName = 'Live TV'
			data.section = payload?.filter?.title
			data.content_discovery_referrer =
				(action.payload && action.payload.content_discovery_referrer) || localStorage.getItem('PreviousPage')
			break
		case GET_CATCHUP_SUCCESS.type:
			pageName = 'Catch Up'
			data.section = payload.filter && payload.filter.title
			data.content_discovery_referrer =
				(action.payload && action.payload.content_discovery_referrer) || window.localStorage.getItem('PreviousPage')
			break
		case 'LOAD_SHOWMAX_PREFERENCES':
			pageName = 'Showmax'
			data.content_discovery_referrer =
				(action.payload && action.payload.content_discovery_referrer) || window.localStorage.getItem('PreviousPage')
			break
		case SEARCH_SUCCESS.type:
			pageName = 'Search'
			data.search = payload.searchKey
			data.content_discovery_referrer =
				(action.payload && action.payload.content_discovery_referrer) || window.localStorage.getItem('CurrentPage')
			break
		case LOGOUT.type:
			pageName = 'Logout'
			break
		case GET_CONTENT_SUCCESS.type:
			pageName = 'Home'
			data.content_discovery_referrer =
				(action.payload && action.payload.content_discovery_referrer) || window.localStorage.getItem('PreviousPage')
			break
		case WATCH_LIST_SUCCESS.type:
			pageName = 'My List'
			data.section = action.payload && action.payload.type
			data.content_discovery_referrer =
				(action.payload && action.payload.content_discovery_referrer) || window.localStorage.getItem('PreviousPage')
			break
		case GET_BOOKMARKS.type:
			if (!action.scheduler) {
				pageName = 'Video Detail'
				data.content_discovery_referrer = window.location.hash.split('/')[1]
				data.asset_episode_title = prevState?.showpage?.data?.billboardDetails?.episodeTitle
				data.asset_title = prevState?.showpage?.data?.billboardDetails?.title
				break
			}
			break
		default:
			console.warn('No matching action found')
	}
	return { pagePayload: data, pageName }
}

export const getSwitchChannelContentCardData = (data) => {
	const channel = data.details.channel
	const program = data.details.program

	if (data) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				asset_title: program?.episodeTitle || '',
				channel_name: channel?.name || '',
				channel_number: channel?.number,
				channel_tag: channel?.tag,
				content_discovery_referrer: 'Live TV Player',
				content_discovery_section: 'Live TV',
				profile_id: getProfileId(),
			},
			type: 'Switch Channel Clicked',
		}
	}
	return {}
}

export const getRemoteConfigData = (payload) => {
	let configList = {}
	if (typeof payload === 'object') {
		Object.values(payload).forEach((configValue) => {
			if (configValue && configValue.flagKey && configValue.flagValue) {
				configList[configValue.flagKey] = configValue.flagValue
			}
		})
	}
	return { contentPayload: configList, type: 'Remote Config Loaded' }
}

export const getEpisodeCardData = (data) => {
	if (data) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				asset_title: data.displayTitle || data.title || '',
				asset_type: data.asset_type,
				content_discovery_referrer: data.content_discovery_referrer,
				content_discovery_section: data.content_discovery_section,
				content_discovery_category: data.content_discovery_category,
				profile_id: getProfileId(),
			},
			type: 'Content Card Clicked',
		}
	}
	return {}
}

export const getContentCardData = (state, data) => {
	const findPreviousContext = (pathHistory) => {
		if (!pathHistory || !Array.isArray(pathHistory)) {
			return 'home'
		}
		const contextPriorities = ['/movies', '/tvshows', '/sport', '/kids']

		for (let i = 0; i < pathHistory.length; i++) {
			const pathname = pathHistory[i]?.pathname

			for (let context of contextPriorities) {
				if (pathname.includes(context)) {
					if (pathname.includes('/contentpage')) {
						for (let j = i + 1; j < pathHistory.length; j++) {
							for (let fallbackContext of contextPriorities) {
								if (pathHistory[j].pathname.includes(fallbackContext)) {
									return fallbackContext.replace('/', '')
								}
							}
						}
					}
					return context.replace('/', '')
				}
			}
		}

		return 'home'
	}

	const previousContext = findPreviousContext(state?.nav?.history)

	const parseLocation = (location) => {
		if (!location || typeof location !== 'string')
			return {
				section: previousContext,
				category: previousContext,
			}

		if (location.startsWith('/contentpage/')) {
			const contentPath = location.split('/contentpage/')[1]
			if (contentPath && contentPath.includes('%2F')) {
				return {
					section: 'home',
					category: decodeURIComponent(contentPath.split('%2F')[1]),
				}
			}

			switch (previousContext) {
				case 'movies':
					return {
						section: 'movies',
						category: 'movies',
					}
				case 'tvshows':
					return {
						section: 'tvshows',
						category: 'tvshows',
					}
				case 'sport':
					return {
						section: 'sports',
						category: 'sports',
					}
				default:
					return {
						section: 'home',
						category: 'home',
					}
			}
		}

		const pathParts = location.split('/').filter(Boolean)
		return {
			section: pathParts[0] || null,
			category: pathParts[0] || null,
		}
	}
	if (data) {
		const { section, category } = parseLocation(data.location)
		return {
			contentPayload: {
				app_version: getAppVersion(),
				asset_title: data.displayTitle || data.title,
				asset_type: data.asset_type,
				channel_name: data.channelName,
				channel_number: data.channelNumber,
				content_discovery_referrer: data.rowTitle || null,
				content_discovery_section: section,
				content_discovery_category: category,
				trackingInfo: data.trackingInfo,
				profile_id: getProfileId(),
				row_type: data.features,
				layout_name: data.location,
				row_name: data.rowTitle,
				asset_age_restriction: data.card_age_restricted_to_age,
				asset_episode_number: data.card_episode_number ? data.card_episode_number : null,
				asset_episode_title: data.card_title || null,
				asset_genre: data.card_genres,
				asset_id: data.card_id,
				asset_license_expiry: data.card_trailers?.[0]?.expiry_date_time || null,
				asset_license_start: data.card_trailers?.[0]?.start_date_time || null,
				asset_season_number: data.card_season_number || null,
				genRef: data.card_trailers?.[0]?.external_reference?.gen_ref || null,
				video_id: data.card_trailers?.[0]?.video_assets?.[0]?.id || null,
				event_id: null,
				series_id: data.card_trailers?.[0]?.program?.series_id || null,
				season_id: data.card_trailers?.[0]?.season_id || null,
				autoplay_time: data?.autoplayTime || null,
				position_in_row: data.index + 1,
			},
			type: 'Content Card Clicked',
		}
	}
	return {}
}

export const getPlaybackErrorData = (playbackItem, playbackData, cast) => {
	let video, channel
	if (cast) {
		video = playbackData?.details?.type == 'vod' ? playbackData.details.details : null
		channel = playbackData.details
	} else {
		video = playbackItem.type == 'vod' ? playbackItem.details : null
		channel = playbackItem.details
	}
	let contentPayload = {
		app_version: getAppVersion(),
		device_detail: getDeviceDetail(),
		session_id: getTrackingSessionId(),
		event_name: 'Video Playback Error',
		event_timestamp: getCurrentTime(),
		device: getDeviceType(),
		device_portfolio: getDevicePortfolio(),
		os: getOperatingSystem(),
		os_version: getOSVersion(),
		user_id: cast ? playbackData?.details?.userId : getUserId(),
		profile_id: getProfileId(),
		heartbeat_interval: 60,
	}
	if (video !== null) {
		return {
			contentPayload: {
				asset_id: video?.genRef,
				channel_id: undefined,
				error_message: cast
					? findInObject(playbackData?.details, 'shakaErrorData')?.[0]?.data?.[2] ?? ''
					: getIn(playbackData?.details, 'message', ''),
				error_reason: cast
					? findInObject(playbackData?.details, 'shakaErrorCode') ?? ''
					: getIn(playbackData?.details, 'code', ''),
				livestream: 'false',
				play_id:
					cast && playbackData?.details?.trackingPlayId
						? playbackData?.details?.trackingPlayId
						: playbackItem?.trackingPlayId || '',
				position: getIn(playbackData.details, 'details.timeStamp', 0),
				total_length: video?.durationInSeconds || 0,
				...contentPayload,
			},
			type: 'Video Playback Error',
		}
	} else if (channel) {
		return {
			contentPayload: {
				channel_id: channel?.details?.id || channel?.id || '',
				channel_tag: channel?.details?.channelTag || channel?.tag || '',
				error_message: cast
					? findInObject(playbackData?.details, 'shakaErrorData')?.[0]?.data?.[2] ?? ''
					: getIn(playbackData?.details, 'message', ''),
				error_reason: cast
					? findInObject(playbackData?.details, 'shakaErrorCode') ?? ''
					: getIn(playbackData?.details, 'code', ''),
				livestream: 'true',
				play_id:
					cast && playbackData?.details?.trackingPlayId
						? playbackData?.details?.trackingPlayId
						: playbackItem?.trackingPlayId || '',
				position: 0,
				total_length: 0,
				...contentPayload,
			},
			type: 'Video Playback Error',
		}
	} else {
		return null
	}
}
export const getHeartbeatData = (playbackItem, playbackData) => {
	let video = playbackItem.video ? playbackItem.video.video : null
	let channel = playbackItem.channel
	if (video) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				asset_id: video.items[0].genRef,
				channel_id: video.items[0].channelId,
				device_detail: getDeviceDetail(),
				event_name: 'Video Content Playing',
				event_timestamp: getCurrentTime(),
				heartbeat_interval: 60,
				livestream: 'false',
				device: getDeviceType(),
				device_portfolio: getDevicePortfolio(),
				os: getOperatingSystem(),
				os_version: getOSVersion(),
				play_id: playbackItem.trackingPlayId,
				position: playbackData,
				session_id: getTrackingSessionId(),
				user_id: getUserId(),
				profile_id: getProfileId(),
				total_length: video.items[0].durationInSeconds,
			},
			type: 'Video Content Playing',
		}
	} else if (channel) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				channel_id: channel.channelId,
				device_detail: getDeviceDetail(),
				event_name: 'Video Content Playing',
				event_timestamp: getCurrentTime(),
				heartbeat_interval: 60,
				livestream: 'true',
				device: getDeviceType(),
				device_portfolio: getDevicePortfolio(),
				os: getOperatingSystem(),
				os_version: getOSVersion(),
				play_id: playbackItem.trackingPlayId,
				session_id: getTrackingSessionId(),
				user_id: getUserId(),
				profile_id: getProfileId(),
				channelTag: channel.channelTag,
				total_length: 0,
				position: 0,
			},
			type: 'Video Content Playing',
		}
	} else {
		return null
	}
}

export const getCdnError = (payload) => {
	return {
		contentPayload: {
			app_version: getAppVersion(),
			device_detail: getDeviceDetail(),
			device: getDeviceType(),
			error: payload,
			device_portfolio: getDevicePortfolio(),
			event_name: 'CDN Error',
			os: getOperatingSystem(),
			os_version: getOSVersion(),
			session_id: getTrackingSessionId(),
			user_id: getUserId(),
			profile_id: getProfileId(),
		},
		type: 'Message Displayed to Customer',
	}
}

export const getPlaybackPausedData = (playbackItem, playbackData) => {
	let video = playbackItem.type == 'vod' ? playbackItem.details : null
	let channel = playbackItem.type == 'live' ? playbackItem.details : null
	if (video) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				asset_id: video.genRef,
				device_detail: getDeviceDetail(),
				device: getDeviceType(),
				device_portfolio: getDevicePortfolio(),
				event_name: 'Video Playback Paused',
				event_timestamp: getCurrentTime(),
				heartbeat_interval: 60,
				livestream: false,
				os: getOperatingSystem(),
				os_version: getOSVersion(),
				play_id: playbackItem.trackingPlayId,
				position: playbackData.currentPosition,
				session_id: getTrackingSessionId(),
				total_length: video.durationInSeconds,
				user_id: getUserId(),
				profile_id: getProfileId(),
			},
			type: 'Video Playback Paused',
		}
	} else if (channel) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				channel_id: channel.channelUuid,
				channel_tag: channel.channelTag,
				device_detail: getDeviceDetail(),
				device: getDeviceType(),
				device_portfolio: getDevicePortfolio(),
				event_name: 'Video Playback Paused',
				event_timestamp: getCurrentTime(),
				heartbeat_interval: 60,
				livestream: true,
				os: getOperatingSystem(),
				os_version: getOSVersion(),
				play_id: channel.id,
				session_id: getTrackingSessionId(),
				position: 0,
				total_length: 0,
				timeshift: Math.abs(window.bitmovin && window.bitmovin.player('player').getTimeShift()),
				user_id: getUserId(),
				profile_id: getProfileId(),
			},
			type: 'Video Playback Paused',
		}
	} else {
		return null
	}
}

export const watchButtonClicked = (data) => {
	return {
		contentPayload: {
			button_text: data.watchButtonText,
			device: getDeviceType(),
			device_detail: getDeviceDetail(),
			device_portfolio: getDevicePortfolio(),
			profile_id: getProfileId(),
			title: data.watchButtonText,
		},
		type: 'Watch Button Clicked',
	}
}

export const onAddToList = () => {
	return {
		contentPayload: contentPayload(),
		type: 'Add To My List Clicked',
	}
}

export const onRemoveFromList = () => {
	return {
		contentPayload: contentPayload(),
		type: 'Removed From My List',
	}
}

const contentPayload = () => {
	return {
		app_version: getAppVersion(),
		device: getDeviceType(),
		device_brand: getDeviceDetail(),
		device_portfolio: getDevicePortfolio(),
		hostname: window.location.hostname,
		path: window.location.href,
		profile_id: getProfileId(),
		referrer: window.location.origin,
		targetUrl: window.location.hash.split('/')[1],
	}
}

export const searchOpened = () => {
	return {
		contentPayload: {
			app_version: getAppVersion(),
			device: getDeviceType(),
			device_brand: getDeviceDetail(),
			device_portfolio: getDevicePortfolio(),
			hostname: window.location.hostname,
			path: window.location.href,
			profile_id: getProfileId(),
			referrer: window.location.origin,
			targetUrl: window.location.hash.split('/')[1],
		},
		type: 'Search Opened',
	}
}

export const searched = (data) => {
	return {
		contentPayload: {
			device: getDeviceType(),
			device_detail: getDeviceDetail(),
			device_portfolio: getDevicePortfolio(),
			profile_id: getProfileId(),
			search_query_term: data.searchKey,
			search_query_source: data.suggestionSelected ? 'keyword' : 'textbox',
		},
		type: 'Searched',
	}
}

const getContentDiscoveryReferrerByLocation = (location) => {
	if (!location || location.trim() === '') {
		return 'not set'
	}

	switch (location) {
		case 'Live TV':
			return 'Live TV Player'
		default:
			return location
	}
}

const getLastSafePath = (historyArray) => {
	const safePaths = [
		'home',
		'catchup',
		'zatvguide',
		'livetv',
		'mystuff',
		'search',
		'tvshows',
		'movies',
		'kids',
		'sport',
		'channelgroups',
	]

	for (let i = historyArray && historyArray.length - 1; i >= 0; i--) {
		const currentPath = historyArray[i].pathname

		const pathParts = currentPath.split('/')
		const desiredPart = pathParts.length > 1 ? pathParts[1] : pathParts[0]

		if (safePaths.includes(desiredPart)) {
			//get the more readable menuName from the path
			const menuName = menuDisplayName(desiredPart)
			return menuName
		}
	}
	// If no safe path is found, return null
	return null
}

export const getPlaybackStartedData = (playbackItem, playbackData, isCast, navState, sectionsState) => {
	let video = playbackItem.type === 'vod' ? playbackItem.details : null
	let channel = playbackItem.type === 'live' ? playbackItem.details : null

	//get the last location user was at based on safe list
	const lastSafePath = getLastSafePath(navState?.history)

	const contentDiscovery = {
		contentDiscoveryCategory:
			sectionsState?.data?.[sectionsState?.activeSection]?.title ||
			playbackItem?.details?.genreGroup ||
			playbackItem?.details?.rowTitle ||
			playbackItem?.content_discovery_referrer,
		contentDiscoverySection: lastSafePath || '',
		contentDiscoveryReferrer:
			playbackItem?.content_discovery_referrer || getContentDiscoveryReferrerByLocation(lastSafePath),
	}

	if (video !== null && (playbackItem.isPlaying !== true || isCast === true || window.isSmartTv)) {
		return getCatchUpPlaybackStartedEvent(playbackItem, playbackData, contentDiscovery)
	}

	if (channel !== null && (playbackItem.playbackStarted !== true || isCast === true || window.isSmartTv)) {
		return getLiveTVPlaybackStartedEvent(playbackItem, playbackData, contentDiscovery)
	}
}

export const getPipStartedData = () => {
	return {
		contentPayload: {
			app_version: getAppVersion(),
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			link_text: 'picture-in-picture',
			pageTitle: `DStv`,
			path: window.location.href,
			referrer: window.location.hostname,
			targetUrl: window.location.pathname,
			profile_id: getProfileId(),
		},
		type: 'Link Clicked',
	}
}
export const getChromecastStartedData = () => {
	return {
		contentPayload: {
			app_version: getAppVersion(),
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			link_text: 'chromecast',
			pageTitle: `DStv`,
			path: window.location.href,
			referrer: window.location.hostname,
			targetUrl: window.location.pathname,
			profile_id: getProfileId(),
		},
		type: 'Link Clicked',
	}
}

export const getSearch = (data) => {
	return {
		contentPayload: {
			SearchKey: data,
			profile_id: getProfileId(),
		},
		type: 'Searched',
	}
}
export const getCatchUpPlaybackStartedEvent = (playbackItem, playbackData, contentDiscovery) => {
	let video = playbackItem.type === 'vod' ? playbackItem.details : null
	let device = getDeviceType()
	let timeInformation = getDayOfWeekAndTimeOfDay()
	console.log('video', video)
	if (video && video.type === 'boxoffice_movies') {
		return {
			type: 'Video Playback Started',
			contentPayload: {},
		}
	} else {
		return {
			type: 'Video Playback Started',
			contentPayload: {
				ad_enabled: false,
				app_version: getAppVersion(),
				asset_age_restriction: getRestrictionRating(video.restrictionRating, video.ratingAdvisory),
				asset_episode_number: video.seasonEpisode || video.episode_number || '',
				asset_episode_title: video.displayItemTitle || '',
				asset_genre: getGenres(video.categorisations),
				asset_id: video.genRef,
				asset_license_expiry: getCorrectDateTimeFormat(video.expiryDateTime),
				asset_license_start: getCorrectDateTimeFormat(video.startDateTime),
				asset_season_number: video.seasonNumber,
				asset_title: video.displayTitle,
				asset_type: video.episode > 0 ? 'series' : 'movie',
				bitrate: +localStorage.getItem('QUALITY_STORAGE_BITRATE') ?? 6000000,
				cast_device: '',
				cast: window.isCast ?? false,
				channel_category: '',
				channel_name: video.channels?.[0]?.channelName || video.channelName || '',
				channel_number: video.channels?.[0]?.channelNumber || video.channelNumber || '',
				channel_tag: video.channels?.[0]?.channelTag || '',
				content_discovery_category: contentDiscovery.contentDiscoveryCategory || '',
				content_discovery_referrer: contentDiscovery.contentDiscoveryReferrer || '',
				content_discovery_section: contentDiscovery.contentDiscoverySection || '',
				content_pod_id: 'DStv',
				day_of_week: timeInformation.dayOfWeek,
				device_detail: getDeviceDetail(),
				device_portfolio: getDevicePortfolio(),
				device: device,
				downloaded: false,
				event_name: playbackItem.event || null,
				full_screen: true,
				livestream: false,
				login_status: true,
				package: getUserPackage(),
				play_id: playbackItem.trackingPlayId,
				position: playbackData && playbackData.time ? playbackData.time : 0,
				product: 'DStv',
				profile_id: getProfileId(),
				quality: localStorage.getItem('QUALITY') ?? 'Higher - up to 1080p HD',
				session_id: window.isCast ? playbackItem.sessionID : getTrackingSessionId(),
				time_of_day: timeInformation.timeOfDay,
				timeoffset: timeInformation.timeZoneOffset,
				total_length: video.durationInSeconds,
				trailer: false,
				usage: 'VOD',
				user_id: window.isCast ? playbackItem.details.userId : getUserId(),
				video_id: video.id,
				video_player: playbackItem.video_player ?? 'Shaka Player 4.6.0',
			},
		}
	}
}

export const getSupportUrlData = (page) => {
	if (page === 'TV Guide') {
		return {
			referrer: 'http://guide.dstv.com/',
			path: 'http://guide.dstv.com/',
		}
	}
	return {
		referrer: window.location.href,
		path: window.location.href,
		profile_id: getProfileId(),
	}
}
export const getChannelOverlayClickedData = () => {
	return {
		contentPayload: {
			app_version: getAppVersion(),
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			link_text: 'Channels',
			pageTitle: `DStv`,
			path: window.location.href,
			referrer: window.location.hostname,
			targetUrl: window.location.pathname,
			profile_id: getProfileId(),
		},
		type: 'Link Clicked',
	}
}

export const getChannelOnNextClickedData = (status) => {
	return {
		contentPayload: {
			app_version: getAppVersion(),
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			link_text: status ? 'OnNext' : 'OnNow',
			pageTitle: `DStv`,
			path: window.location.href,
			referrer: window.location.hostname,
			targetUrl: window.location.pathname,
			profile_id: getProfileId(),
		},
		type: 'Link Clicked',
	}
}

export const getMenuTapData = (data) => {
	let supportUrlData = getSupportUrlData(data.payload || data)
	return {
		contentPayload: {
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			link_text: window.location.hash.split('/')[1],
			path: supportUrlData.path,
			referrer: window.location.origin,
			targetUrl: window.location.pathname,
		},
		type: 'Link Clicked',
	}
}

export const getCatchupSeekCompletedEvent = (playbackItem, playbackData) => {
	let video = playbackItem.details || null
	return {
		type: 'Video Playback Seek Completed',
		contentPayload: {
			app_version: getAppVersion(),
			asset_id: video && video.genRef,
			device_detail: getDeviceDetail(),
			device: getDeviceType(),
			device_portfolio: getDevicePortfolio(),
			event_name: 'Video Playback Seek Completed',
			event_timestamp: getCurrentTime(),
			heartbeat_interval: 60,
			livestream: false,
			os: getOperatingSystem(),
			os_version: getOSVersion(),
			play_id: playbackItem.trackingPlayId,
			position: playbackData.previousPosition,
			session_id: getTrackingSessionId(),
			start_position: playbackData.previousPosition,
			end_position: playbackData.newPosition,
			total_length: video && video.durationInSeconds,
			user_id: getUserId(),
			profile_id: getProfileId(),
		},
	}
}
export const getPlaybackResumedData = (playbackItem, playbackData) => {
	let video = playbackItem.type == 'vod' ? playbackItem.details : null
	let channel = playbackItem.type == 'live' ? playbackItem.details : null
	if (video) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				asset_id: video.genRef,
				device_detail: getDeviceDetail(),
				device: getDeviceType(),
				device_portfolio: getDevicePortfolio(),
				event_name: 'Video Playback Resumed',
				event_timestamp: getCurrentTime(),
				heartbeat_interval: 60,
				livestream: false,
				os: getOperatingSystem(),
				os_version: getOSVersion(),
				play_id: playbackItem.trackingPlayId,
				position: playbackData.currentPosition,
				session_id: getTrackingSessionId(),
				total_length: video.durationInSeconds,
				user_id: getUserId(),
				profile_id: getProfileId(),
			},
			type: 'Video Playback Resumed',
		}
	} else if (channel) {
		return {
			contentPayload: {
				app_version: getAppVersion(),
				channel_id: channel.channelUuid,
				channel_tag: channel.channelTag,
				device_detail: getDeviceDetail(),
				device: getDeviceType(),
				device_portfolio: getDevicePortfolio(),
				event_name: 'Video Playback Resumed',
				event_timestamp: getCurrentTime(),
				heartbeat_interval: 60,
				livestream: true,
				os: getOperatingSystem(),
				os_version: getOSVersion(),
				play_id: channel.id,
				session_id: getTrackingSessionId(),
				position: 0,
				total_length: 0,
				timeshift: Math.abs(window.bitmovin && window.bitmovin.player('player').getTimeShift()),
				user_id: getUserId(),
				profile_id: getProfileId(),
			},
			type: 'Video Playback Resumed',
		}
	} else {
		return null
	}
}
export const getLiveTVPlaybackStartedEvent = (playbackItem, playbackData, contentDiscovery) => {
	let timeInformation = getDayOfWeekAndTimeOfDay()
	let channel = playbackItem.details

	return {
		type: 'Video Playback Started',
		contentPayload: {
			ad_enabled: false,
			app_version: getAppVersion(),
			asset_age_restriction: channel.maturityClassification ?? channel.rating ?? '',
			asset_episode_number: channel.episode_number ?? '',
			asset_episode_title: channel.event_episode_title ?? channel.episodeTitle ?? '',
			asset_id: channel.id ?? '',
			asset_license_expiry: '',
			asset_license_start: '',
			asset_season_number: channel.event_season_number ?? '',
			asset_title: channel.title ?? '',
			bitrate: +localStorage.getItem('QUALITY_STORAGE_BITRATE') ?? 6000000,
			cast_device: '',
			cast: window.isCast || false,
			channel_category: channel.channelCategory ?? channel.channelCategory ?? channel.channel_genres?.[0] ?? '',
			channel_id: channel ? channel.channelUuid : '',
			channel_name: channel.channelName ?? channel.channel_name ?? '',
			channel_number: channel.channelNumber ?? channel.number ?? channel?.channel_number ?? '',
			channel_tag: channel ? channel.channelTag : '',
			content_discovery_category: contentDiscovery.contentDiscoveryCategory ?? '',
			content_discovery_referrer: contentDiscovery.contentDiscoveryReferrer ?? '',
			content_discovery_section: contentDiscovery.contentDiscoverySection ?? '',
			content_pod_id: 'DStv',
			day_of_week: timeInformation.dayOfWeek,
			device_detail: getDeviceDetail(),
			device_portfolio: getDevicePortfolio(),
			device: getDeviceType(),
			downloaded: false,
			event_name: playbackItem.event || null,
			full_screen: true,
			livestream: true,
			login_status: true,
			package: getUserPackage(),
			play_id: playbackItem.trackingPlayId,
			position: 0,
			product: 'DStv',
			profile_id: getProfileId() || null,
			quality: localStorage.getItem('QUALITY') ?? 'Higher - up to 1080p HD',
			session_id: window.isCast ? playbackItem.sessionID : getTrackingSessionId(),
			time_of_day: timeInformation.timeOfDay,
			timeoffset: timeInformation.timeZoneOffset,
			trailer: false,
			usage: 'Live',
			user_id: window.isCast ? playbackItem.userId : getUserId(),
			video_id: channel.id ?? '',
			video_player: playbackItem.video_player ?? 'Shaka Player 4.6.0',
		},
	}
}

export const getLiveTVResumeEvent = (playbackItem) => {
	let channel = playbackItem.channel
	return {
		type: 'Video Playback Resumed',
		contentPayload: {
			app_version: getAppVersion(),
			channel_id: channel.channelNumber,
			device: getDeviceType(),
			device_portfolio: 'Web',
			device_detail: getDeviceDetail(),
			event_name: 'Video Playback Resume',
			event_timestamp: getCurrentTime(),
			heartbeat_interval: 60,
			livestream: false,
			os: getOperatingSystem(),
			os_version: getOSVersion(),
			play_id: playbackItem.trackingPlayId,
			session_id: getTrackingSessionId(),
			user_id: getUserId(),
			timeshift: Math.abs(window.bitmovin.player('player').getTimeShift()),
			profile_id: getProfileId(),
		},
	}
}
export const getCorrectDateTimeFormat = (thisTime) => {
	return date.format(new Date(thisTime), 'YYYY-MM-DD hh:mm:ss.SS A').replace('a.m.', 'AM').replace('p.m.', 'PM')
}

export const getDayOfWeekAndTimeOfDay = () => {
	let now = new Date()
	let timeZone = date.format(now, 'Z')
	return {
		dayOfWeek: date.format(now, 'dddd'),
		timeOfDay: date.format(now, 'H'),
		timeZoneOffset: timeZone.slice(0, timeZone.length - 2) + ':' + timeZone.slice(timeZone.length - 2),
	}
}

export const getRestrictionRating = (restrictionRating, ratingAdvisory) => {
	return `${restrictionRating ? restrictionRating.ageRestrictedToAge : ''} ${ratingAdvisory}`
}
export const getGenres = (categorisations) => {
	let returnList = []
	if (typeof categorisations !== 'undefined') {
		categorisations.forEach((category) => {
			returnList.push(category.subCategoryName)
		})
	}
	return returnList.join(', ')
}
export const getCurrentTime = () => {
	let d = new Date()
	return d.getTime()
}
export const getOperatingSystem = () => {
	let OSName = 'Unknown OS'
	if (localStorage.getItem('PRODUCT_TYPE') === 'XBOX')
		// eslint-disable-next-line no-undef
		OSName = Windows.Security.ExchangeActiveSyncProvisioning.EasClientDeviceInformation().operatingSystem
	if (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE') OSName = 'Hisense Vewd'
	if (localStorage.getItem('PRODUCT_TYPE') === 'TIZEN') OSName = 'Tizen'
	if (localStorage.getItem('PRODUCT_TYPE') === 'WEBOS') OSName = 'webOS'
	return OSName
}

export const getOSVersion = () => {
	let productType = localStorage.getItem('PRODUCT_TYPE') || 'Unknown'
	switch (productType) {
		case 'XBOX': {
			try {
				// eslint-disable-next-line no-undef
				var versionnumber = Windows.System.Profile.AnalyticsInfo.versionInfo.deviceFamilyVersion
				var versionnumberlong = parseInt(versionnumber).toString(16)
				var bit00 = parseInt(versionnumberlong.slice(versionnumberlong.length - 4), 16)
				var bit16 = parseInt(versionnumberlong.slice(versionnumberlong.length - 8, versionnumberlong.length - 4), 16)
				var bit32 = parseInt(versionnumberlong.slice(versionnumberlong.length - 12, versionnumberlong.length - 8), 16)
				var bit48 = parseInt(versionnumberlong.slice(0, versionnumberlong.length - 12), 16)
				return [bit48, bit32, bit16, bit00].join('.')
			} catch (e) {
				return 'Unknown OS Version'
			}
		}
		case 'HISENSE': {
			try {
				return Hisense_GetFirmWareVersion() // eslint-disable-line no-undef
			} catch (e) {
				return 'Hisense'
			}
		}
		case 'TIZEN': {
			try {
				var v = navigator.appVersion.match(/\(.*?\)/)
				if (v) {
					return v[0].replace(/[()]/g, '')
				}
			} catch (e) {
				return 'Unknown OS Version'
			}
			break
		}
		case 'WEBOS': {
			try {
				var va = navigator.appVersion.match(/\(.*?\)/)
				if (va) {
					return va[0].replace(/[()]/g, '')
				}
			} catch (e) {
				return 'Unknown OS Version'
			}
			break
		}
		default: {
			return 'Unknown OS Version'
		}
	}
}

export const getBrowserType = () => {
	//Check if browser is IE
	if (navigator.userAgent.search('MSIE') >= 0 || navigator.userAgent.search('Edge') >= 0) {
		return 'INTERNET EXPLORER'
	}
	//Check if browser is Chrome
	else if (navigator.userAgent.search('Chrome') >= 0) {
		return 'CHROME'
	}
	//Check if browser is Firefox
	else if (navigator.userAgent.search('Firefox') >= 0) {
		return 'FIREFOX'
	}
	//Check if browser is Safari
	else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
		return 'SAFARI'
	}
	//Check if browser is Opera
	else if (navigator.userAgent.search('Opera') >= 0) {
		return 'OPERA'
	} else {
		return 'unknown'
	}
}

export const getContentDiscoveryData = (playbackItem) => {
	let contentDiscoveryReferrer = playbackItem.content_discovery_referrer
		? playbackItem.content_discovery_referrer
		: playbackItem.actionPayload && playbackItem.actionPayload.payload
		? playbackItem.actionPayload.payload.content_discovery_referrer
		: ''
	let contentDiscoverCategory = playbackItem.content_discovery_category
		? playbackItem.content_discovery_category
		: playbackItem.actionPayload && playbackItem.actionPayload.payload
		? playbackItem.actionPayload.payload.content_discovery_category
		: ''
	let contentDiscoverSection = playbackItem.content_discovery_section
		? playbackItem.content_discovery_section
		: playbackItem.actionPayload && playbackItem.actionPayload.payload
		? playbackItem.actionPayload.payload.content_discovery_section
		: ''

	if (
		contentDiscoveryReferrer === '' &&
		contentDiscoverCategory === '' &&
		contentDiscoverSection === '' &&
		playbackItem.actionPayload &&
		playbackItem.actionPayload.episode
	) {
		let episode = playbackItem.actionPayload.episode
		contentDiscoveryReferrer = episode.content_discovery_referrer ? episode.content_discovery_referrer : ''
		contentDiscoverSection = episode.content_discovery_section ? episode.content_discovery_section : ''
		contentDiscoverCategory = episode.content_discovery_category ? episode.content_discovery_category : ''
	}

	if (
		contentDiscoveryReferrer === '' &&
		contentDiscoverCategory === '' &&
		contentDiscoverSection === '' &&
		playbackItem.billboardPayload
	) {
		contentDiscoveryReferrer = playbackItem.billboardPayload.content_discovery_referrer
			? playbackItem.billboardPayload.content_discovery_referrer
			: ''
		contentDiscoverSection = playbackItem.billboardPayload.content_discovery_section
			? playbackItem.billboardPayload.content_discovery_section
			: ''
		contentDiscoverCategory = playbackItem.billboardPayload.content_discovery_category
			? playbackItem.billboardPayload.content_discovery_category
			: ''
	}
	return {
		contentDiscoveryReferrer,
		contentDiscoverCategory,
		contentDiscoverSection,
	}
}
export const getTrackingSessionId = () => {
	let sessionID = secureSessionStorage.getItem('trackingSession')
	if (sessionID) {
		return sessionID
	} else {
		sessionID = uuidv4()
		secureSessionStorage.setItem('trackingSession', sessionID)
		return sessionID
	}
}

export const getUserId = () => {
	return secureLocalStorage.getItem('userID')
}

export const getUserName = () => {
	return secureLocalStorage.getItem('userName')
}

export const getAppVersion = () => {
	return process.env.APP_VERSION
}
export const getDevicePortfolio = () => {
	if (localStorage.getItem('PRODUCT_TYPE') === 'EXPLORA_ULTRA') return 'DStv Devices'
	else return 'Leanback'
}
export const getDeviceDetail = () => {
	try {
		switch (localStorage.getItem('PRODUCT_TYPE')) {
			case 'XBOX':
				return 'Xbox'
			case 'EXPLORA_ULTRA':
				return 'Explora Ultra'
			case 'WEBOS':
				return 'LG TV'
			case 'TIZEN':
				return 'Samsung TV'
			case 'STREAMA':
				return 'Streama'
			case 'CHROME_CAST':
				return 'Chromecast'
			default:
				return 'Hisense'
		}
		// eslint-disable-line no-undef
	} catch (e) {
		switch (localStorage.getItem('PRODUCT_TYPE')) {
			case 'XBOX':
				return 'Xbox'
			case 'EXPLORA_ULTRA':
				return 'Explora Ultra'
			case 'STREAMA':
				return 'Streama'
			default:
				return 'Hisense'
		}
	}
}
export const getDeviceType = () => {
	switch (localStorage.getItem('PRODUCT_TYPE')) {
		case 'XBOX':
			return 'Gaming Console'
		case 'EXPLORA_ULTRA':
			return 'Explora'
		case 'STREAMA':
			return 'Streama'
		case 'CHROME_CAST':
			return 'Chromecast'
		default:
			return 'Smart TV'
	}
}
export const areAdsEnabled = () => {
	return !(document.getElementById('adBlockDetection') && document.getElementById('adBlockDetection').offsetHeight <= 1)
}

export const getBitRate = (videoQualitiesArray, videoSegmentData) => {
	if (videoQualitiesArray !== null && videoQualitiesArray !== '') {
		if (videoSegmentData !== null && videoSegmentData !== '' && videoSegmentData['id'] !== '') {
			for (var i = 0; i < videoQualitiesArray.length; i++) {
				var bitrate = videoQualitiesArray[i]
				if (bitrate['id'] === videoSegmentData['id']) {
					return bitrate['label'].split(',')[1]
				}
			}
		} else if (videoQualitiesArray.length > 0) {
			return videoQualitiesArray[videoQualitiesArray.length - 1]['label'].split(',')[1]
		} else {
			return 'unavailable'
		}
	}
	return 'unavailable'
}

export const getButtonClicked = (state, payload) => {
	return {
		type: 'Button Clicked',
		contentPayload: {
			app_version: getAppVersion(),
			button_name: payload.buttonText,
			device: getDeviceType(),
			device_detail: getDeviceDetail(),
			device_portfolio: getDevicePortfolio(),
			os: getOperatingSystem(),
			os_version: getOSVersion(),
			profile_id: getProfileId() || null,
			title: payload.title || state.details.title || null,
			user_id: getUserId(),
		},
	}
}

export const notificationOverlayDisplayed = (payload) => {
	return {
		contentPayload: {
			overlay_name: payload.overlay,
			device_portfolio: getDevicePortfolio(),
			device: getDeviceType(),
			device_detail: getDeviceDetail(),
			profile_id: getProfileId(),
			title: payload.title,
		},
		type: 'Notification Overlay Displayed',
	}
}

export const getAutoplayButtonData = (payload) => {
	return {
		contentPayload: {
			overlay_name: payload.overlay,
			device_portfolio: getDevicePortfolio(),
			device: getDeviceType(),
			device_detail: getDeviceDetail(),
			profile_id: getProfileId(),
			title: payload.title || 'not set',
			button_text: payload.buttonText || null,
		},
		type: 'Notification Overlay Button Clicked',
	}
}

export const getSettingData = (payload) => {
	return {
		contentPayload: {
			setting_section: 'Playback & Data Usage',
			setting_name: 'Autoplay Next Episode',
			action: payload.enabled === true ? 'Enabled' : 'Disabled',
		},
		type: 'Autoplay Toggle setting',
	}
}
export const getSegmentData = (data) => {
	let supportUrlData = getSupportUrlData(data)
	return {
		contentPayload: {
			app_version: getAppVersion(),
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			link_text: data.text,
			pageTitle: data.title,
			path: supportUrlData.path,
			referrer: supportUrlData.referrer,
			targetUrl: data.targetUrl,
			profile_id: getProfileId(),
		},
		type: 'Link Clicked',
	}
}

export const getStreamaAnalytics = (data) => {
	let { path, referrer } = getSupportUrlData(data)

	const { button_text, pageTitle } = data

	//Page title and event type is same in most cases
	return {
		contentPayload: {
			device: getDeviceType(),
			device_detail: getDeviceDetail(),
			device_portfolio: getDevicePortfolio(),
			app_version: getAppVersion(),
			currentUrl: window.location.href,
			hostname: window.location.hostname,
			path,
			referrer,
			button_text,
			pageTitle,
		},
		type: 'Button Clicked on ' + pageTitle,
	}
}
