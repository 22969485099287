/* eslint-disable no-inner-declarations */
import {
	getPageData,
	getContentCardData,
	getHeartbeatData,
	getPlaybackStartedData,
	getRemoteConfigData,
	getMenuTapData,
	getSwitchChannelContentCardData,
	getPlaybackErrorData,
	getEpisodeCardData,
	getChannelOverlayClickedData,
	getChromecastStartedData,
	getPipStartedData,
	getSegmentData,
	getChannelOnNextClickedData,
	getPlaybackPausedData,
	getPlaybackResumedData,
	getCatchupSeekCompletedEvent,
	getButtonClicked,
	getAutoplayButtonData,
	getSettingData,
	notificationOverlayDisplayed,
	getCdnError,
	watchButtonClicked,
	onAddToList,
	onRemoveFromList,
	searched,
	searchOpened,
} from './reportData'
import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { segmentReporter } from './reporter'
import { isObservable } from 'rxjs'
import {
	getConnectSessionRefreshResponseData,
	getPlayerErrorLog,
	getRemoteConfigError,
	getUnauthorizedResponseData,
	getMessageDisplayedToCustomerPayload,
	getConcurrencyData,
} from '../Log/logData'
import {
	AUTOPLAY_TOGGLE,
	DISPLAY_NOTIFICATION_OVERLAY,
	EDITORIALS,
	NAV_PUSH,
	GET_BOOKMARKS,
	GET_CATCHUP_SUCCESS,
	GET_CDN_TOKEN_ERROR,
	GET_CHANNELS_SUCCESS,
	GET_ENTITLEMENT_ERROR,
	GET_CONTENT_SUCCESS,
	GET_REMOTE_CONFIGS_ERROR,
	GET_REMOTE_CONFIGS_SUCCESS,
	getBy,
	getSegmentId,
	LOGOUT,
	MENU_TOGGLE,
	NOTIFICATION_OVERLAY_BUTTON_CLICKED,
	PAUSE,
	PLAY,
	PLAYER_ERROR,
	RESUME_PLAY,
	SEARCH_SUCCESS,
	SEEK,
	SWITCH_CHANNEL,
	WATCH_LIST_SUCCESS,
	TOGGLE_WATCHLIST,
	BACKTO_LIVE,
	WATCH_FROM_START_LIVE,
	WATCH_BUTTON_CLICKED,
	SEARCHED,
	SEARCH_OPENED,
} from '@dstv-web-leanback/dstv-frontend-services'

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
const route_signin_regex = /(tv|applock)/i
let player = createDraftSafeSelector(
	(state) => state.player,
	(v) => v
)

let nav = createDraftSafeSelector(
	(state) => state.nav,
	(v) => v
)

let sections = createDraftSafeSelector(
	(state) => state.sections,
	(v) => v
)

const menu_update = (_s, action) => Object.assign(getMenuTapData(action.payload, _s), getPageData(action))
const pageStateData = (state, action) => getPageData(action, state)
const playPause = (state, { type, payload }) => {
	let pl = player(state)
	let fn = /pause/i.test(type) ? getPlaybackPausedData : getPlaybackResumedData

	return fn(pl, payload) || {}
}

const fnMap = {
	getPageData: (_s, action) => getPageData(action),
	[LOCATION_CHANGE]: (_s, { type, payload, ...restA }) => {
		let { pathname = '/home', isContentCardClick = false, payload: dat = {} } = payload
		return route_signin_regex.test(pathname)
			? { ...getPageData({ type, payload }), pageName: 'TV Sign In' }
			: isContentCardClick
			? getContentCardData(dat)
			: {}
	},
	[GET_CDN_TOKEN_ERROR.type]: (_s, { payload }) => getCdnError(payload),
	[NOTIFICATION_OVERLAY_BUTTON_CLICKED.type]: (_s, { payload }) => getAutoplayButtonData(payload),
	[DISPLAY_NOTIFICATION_OVERLAY.type]: (_s, { payload }) => notificationOverlayDisplayed(payload),
	[AUTOPLAY_TOGGLE.type]: (_s, { payload }) => getSettingData(payload),
	[NAV_PUSH]: (_s, { payload }) => getContentCardData(_s, payload),
	[EDITORIALS.type]: (_s, { scheduler = false, payload }) =>
		!scheduler ? getContentCardData(payload.item || payload.tryThisPayload) : {},
	PLAYER_CALLBACK_PLAY: (state, { payload }) => {
		let isCast = window.isCast
		return isCast
			? getPlaybackStartedData(payload.playerInfo, payload.data, isCast)
			: getPlaybackStartedData(player(state), payload.data)
	},
	[SWITCH_CHANNEL.type]: (state, { payload }) => getSwitchChannelContentCardData(payload),
	PLAYER_CALLBACK_CASTSTARTED: (state, _act) => getChromecastStartedData(player(state)),
	PLAYER_CALLBACK_PICTUREINPICTURESTARTED: (state, _act) => getPipStartedData(player(state)),
	[PAUSE.type]: playPause,
	[RESUME_PLAY.type]: playPause,
	[PLAY.type]: (state, action) => {
		//this is the initial state of video ( so when video is loaded and played )
		let cast = window.isCast === true
		return getPlaybackStartedData(player(state), action.payload, cast, nav(state), sections(state))
	},
	[PLAYER_ERROR.type]: (state, original_action) => {
		let action = { ...original_action }
		let data = {}
		// TODO: Fix the underlying functions as an error causes the premature exit of the middleware
		let pl = player(state)
		let cast = window.isCast === true
		data = getPlaybackErrorData(pl, action.payload, cast)
		let loggingData = getPlayerErrorLog(action, state)
		if (data) {
			data.loggingData = loggingData
		}
		return data
	},
	[BACKTO_LIVE.type]: (state, action) => {
		return getButtonClicked(player(state), action.payload)
	},
	[WATCH_FROM_START_LIVE.type]: (state, action) => {
		return getButtonClicked(player(state), action.payload)
	},
	[GET_REMOTE_CONFIGS_ERROR.type]: (_s, action) => {
		try {
			return getRemoteConfigError(action)
		} catch (e) {
			console.log(e)
		}
		return {}
	},
	[GET_REMOTE_CONFIGS_SUCCESS.type]: (_s, { payload }) => {
		try {
			return getRemoteConfigData(payload)
		} catch (e) {
			console.error('GET_CONFIG' + e)
		}
		return {}
	},
	[LOGOUT.type]: (state, action) =>
		Object.assign(getPageData(action), { loggingData: getConnectSessionRefreshResponseData(action, state) }),
	[GET_ENTITLEMENT_ERROR.type]: (state, action) => ({
		loggingData: getUnauthorizedResponseData(action, state),
	}),
	GET_CONCURRENCY_STATUS_ERROR: (state, action) => ({ loggingData: getConcurrencyData(action, state) }),
	CHANNEL_OVERLAY_SHOWN: (state, _act) => getChannelOverlayClickedData(state),
	CHANNEL_ON_NEXT_ON_NOW: (_s, { payload }) => getChannelOnNextClickedData(payload?.isOnNext),
	[MENU_TOGGLE.type]: menu_update,
	SUPPORT_LINKS: menu_update,
	SEGMENT_REPORT: (_s, { payload }) => (payload.Type === 'Link Clicked' ? getSegmentData(payload.payload || {}) : {}),
	[GET_CONTENT_SUCCESS.type]: pageStateData,
	[GET_CHANNELS_SUCCESS.type]: pageStateData,
	[GET_CATCHUP_SUCCESS.type]: pageStateData,
	[SEARCH_SUCCESS.type]: pageStateData,
	[WATCH_LIST_SUCCESS.type]: pageStateData,
	[WATCH_BUTTON_CLICKED.type]: (_s, { payload }) => watchButtonClicked(payload),
	[TOGGLE_WATCHLIST.type]: (_s, { payload }) => (payload.operation === 'REMOVE' ? onRemoveFromList() : onAddToList()),
	[SEARCHED]: (_s, { payload }) => searched(payload),
	[SEARCH_OPENED]: searchOpened,
	ROUTE_TO_MY_LIST: (_s, action) => Object.assign(getContentCardData(action.payload), getPageData(action)),
	[GET_BOOKMARKS.type]: pageStateData,
	[SEEK.type]: (state, { payload = {} }) => getCatchupSeekCompletedEvent(player(state), payload),
}

export const ReportMiddleware = (store) => (next) => (action) => {
	if (action) {
		let { type = '' } = action

		if (isObservable(action)) {
			const $sub = action.subscribe((act) => {
				next(act)
			})
			setTimeout(() => $sub.unsubscribe(), 10)
			return
		}
		next(action)

		function reporter() {
			let cb = action.callback || null
			if (fnMap[type] !== undefined) {
				let result = fnMap[type](store.getState(), action)
				if (!result) {
					return
				}
				let { loggingData = false, ...data } = result

				segmentReporter(data, cb)
				if (loggingData && typeof loggingData !== 'undefined') {
					segmentReporter(loggingData, cb)
				}
			}
		}
		setTimeout(reporter, 10)
	}
}

export const initSegment = function () {
	// Segment snippet
	let analytics = []

	if (typeof window.analytics === 'undefined') {
		analytics.initialize = false
	} else {
		analytics = window.analytics
	}
	if (!analytics.initialize)
		if (analytics.invoked) {
			console.error && console.error('Segment snippet included twice.')
		} else {
			analytics.invoked = !0
			analytics.methods = [
				'trackSubmit',
				'trackClick',
				'trackLink',
				'trackForm',
				'pageview',
				'identify',
				'reset',
				'group',
				'track',
				'ready',
				'alias',
				'debug',
				'page',
				'once',
				'off',
				'on',
				'addSourceMiddleware',
				'addIntegrationMiddleware',
				'setAnonymousId',
				'addDestinationMiddleware',
			]
			analytics.factory = function (e) {
				return function () {
					var t = Array.prototype.slice.call(arguments)
					t.unshift(e)
					analytics.push(t)
					return analytics
				}
			}
			for (var e = 0; e < analytics.methods.length; e++) {
				var key = analytics.methods[e]
				analytics[key] = analytics.factory(key)
			}
			analytics.load = function (key, e) {
				var t = document.createElement('script')
				t.type = 'text/javascript'
				t.async = !0
				t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
				var n = document.getElementsByTagName('script')[0]
				n.parentNode.insertBefore(t, n)
				analytics._loadOptions = e
			}
			const segmentKey = getSegmentId()
			analytics._writeKey = segmentKey
			analytics.SNIPPET_VERSION = '4.15.2'
			analytics.load(segmentKey)
			analytics.page()
			window.analytics = analytics
		}
	return analytics
}

export const postAPIErrorToSegment = (error, serverError) => {
	let errorDesc = ''

	if (serverError?.errorMessage) {
		errorDesc = serverError.errorMessage
	} else if (error?.mainHeading === 'No internet connection') {
		errorDesc = 'noNetwork'
	} else if (serverError?.reason) {
		errorDesc = 'API Error'
	} else if (!serverError) {
		errorDesc = 'Fatal Error'
	}

	let contentPayload = getMessageDisplayedToCustomerPayload({
		errorMessage: (error.mainHeading ? error.mainHeading : '') + ' ' + (error.subHeading ?? error.body),
		errorReason: errorDesc,
	})

	return segmentReporter(contentPayload)
}
