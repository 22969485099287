import styles from './Button.module.scss'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function Button({ active, selected, icon, id, onItemFocus, onSelect, title, isTitleToggle = false }) {
	const { ref, focused } = useFocusable({
		onEnterPress: onSelect,
		onFocus: onItemFocus,
		focusKey: `BUTTON_${id.toString()?.toUpperCase()}`,
	})

	return (
		<button
			ref={ref}
			className={`${styles.button} ${selected && !focused ? styles.selected : ''} ${
				active || focused ? styles.active : ''
			}`}
		>
			{icon && <img className={focused ? styles.invert : ''} src={icon} alt="" />}
			{(!isTitleToggle || focused) && title && <span>{title}</span>}
		</button>
	)
}
export default Button
