import { combineReducers } from '@reduxjs/toolkit'

import {
	AD_PLAYER_FEATURE_KEY,
	adPlayerReducer,
	AUTH_FEATURE_KEY,
	authReducer,
	AUTOPLAY_FEATURE_KEY,
	autoplayReducer,
	AVATARS_FEATURE_KEY,
	avatarsReducer,
	BILLBOARD_FEATURE_KEY,
	billboardReducer,
	BOOKMARKS_FEATURE_KEY,
	bookmarksReducer,
	CATCHUP_FEATURE_KEY,
	catchupReducer,
	CDN_AUTH_FEATURE_KEY,
	cdnAuthReducer,
	CHANNELS_FEATURE_KEY,
	channelsReducer,
	CONFIG_FEATURE_KEY,
	configReducer,
	DEVICES_FEATURE_KEY,
	devicesReducer,
	ENTITLEMENT_FEATURE_KEY,
	entitlementReducer,
	HOME_FEATURE_KEY,
	homeReducer,
	KEYBOARD_FEATURE_KEY,
	keyboardReducer,
	LOGOUT,
	MENU_FEATURE_KEY,
	menuReducer,
	NAV_FEATURE_KEY,
	navReducer,
	ONBOARDING_FEATURE_KEY,
	onboardingReducer,
	PLAYER_FEATURE_KEY,
	playerReducer,
	PROFILE_FEATURE_KEY,
	profileReducer,
	PROXIMITY_FEATURE_KEY,
	proximityReducer,
	SEARCH_FEATURE_KEY,
	searchReducer,
	SECTIONS_FEATURE_KEY,
	sectionsReducer,
	SESSION_FEATURE_KEY,
	sessionReducer,
	SETTINGS_FEATURE_KEY,
	settingsReducer,
	SHOWPAGE_FEATURE_KEY,
	showpageReducer,
	TERMS_FEATURE_KEY,
	termsReducer,
	USER_FEATURE_KEY,
	userReducer,
	WATCH_LIST_FEATURE_KEY,
	watchlistReducer,
} from '@dstv-web-leanback/dstv-frontend-services'

const appReducer = combineReducers({
	[TERMS_FEATURE_KEY]: termsReducer,
	[NAV_FEATURE_KEY]: navReducer,
	[AD_PLAYER_FEATURE_KEY]: adPlayerReducer,
	[WATCH_LIST_FEATURE_KEY]: watchlistReducer,
	[SHOWPAGE_FEATURE_KEY]: showpageReducer,
	[AUTH_FEATURE_KEY]: authReducer,
	[AUTOPLAY_FEATURE_KEY]: autoplayReducer,
	[AVATARS_FEATURE_KEY]: avatarsReducer,
	[BILLBOARD_FEATURE_KEY]: billboardReducer,
	[CATCHUP_FEATURE_KEY]: catchupReducer,
	[CDN_AUTH_FEATURE_KEY]: cdnAuthReducer,
	[CHANNELS_FEATURE_KEY]: channelsReducer,
	[CONFIG_FEATURE_KEY]: configReducer,
	[DEVICES_FEATURE_KEY]: devicesReducer,
	[ENTITLEMENT_FEATURE_KEY]: entitlementReducer,
	[HOME_FEATURE_KEY]: homeReducer,
	[PROXIMITY_FEATURE_KEY]: proximityReducer,
	[KEYBOARD_FEATURE_KEY]: keyboardReducer,
	[MENU_FEATURE_KEY]: menuReducer,
	[PLAYER_FEATURE_KEY]: playerReducer,
	[PROFILE_FEATURE_KEY]: profileReducer,
	[SEARCH_FEATURE_KEY]: searchReducer,
	[SECTIONS_FEATURE_KEY]: sectionsReducer,
	[SESSION_FEATURE_KEY]: sessionReducer,
	[SETTINGS_FEATURE_KEY]: settingsReducer,
	[USER_FEATURE_KEY]: userReducer,
	[BOOKMARKS_FEATURE_KEY]: bookmarksReducer,
	[ONBOARDING_FEATURE_KEY]: onboardingReducer,
})

const rootReducer = (state, action) => {
	if (action.type === LOGOUT.type) {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default rootReducer
