import { replaceVirtualLinks } from '@dstv-web-leanback/dstv-frontend-services'
import { getBy } from './objHelper'

//This is the card info URL for PB
export const getCardInfoURL = (response, type) => {
	//checking for type and passing it, as homepage has events and card info
	let data =
		response &&
		response.links &&
		response.links.filter((item) => {
			if (item.rel[0] === 'item' && item.rel[1] === type) {
				return item
			} else if (type === 'related-content' && item.rel.includes('related-content')) {
				return item
			}
		})
	return data?.length && replaceVirtualLinks(data[0].href)
}

export const getBillboardInfoURL = (links, type) => {
	let data =
		links &&
		links.filter((item) => {
			if (type === 'parent' && item.rel.includes('parent')) {
				return item
			} else if (type === 'related-content' && item.rel.includes('related-content')) {
				return item
			} else if (type === 'self' && item.rel.includes('self')) {
				return item
			} else if (type === 'watchlist-info' && item.rel.includes('watchlist-info')) {
				return item
			} else if (type === 'continue-watching-info' && item.rel.includes('continue-watching-info')) {
				return item
			}
		})
	return data?.length && replaceVirtualLinks(data[0].href)
}

//This the channel details for the VOD items on home.. Like the logo etc
export const getCardChannelInfoURL = (response) => {
	let data =
		response &&
		response.card_channel &&
		response.card_channel.filter((item) => {
			return item.rel[0] === 'channel'
		})
	return data?.length && replaceVirtualLinks(data[0].href)
}

export const getEventThumbURL = (response) => {
	let data =
		response &&
		response.event_images &&
		response.event_images.filter((item) => {
			return item.rel[0] === 'thumbnailUri' && item.rel[1] === 'THUMB'
		})
	return data.length && data[0].href
}

export const getCardLogoURL = (response, key) => {
	if (response.images && Array.isArray(response.images)) {
		let data = response.images.filter((item) => {
			return item.rel.includes(key)
		})
		return data[0]?.href
	} else {
		let data =
			response &&
			response.card_images &&
			response.card_images.filter((item) => {
				return item.rel[0] === 'NowWeb' && item.rel[1] === 'MEDIUM'
			})
		return data?.length && data[0]?.href
	}
}

export const getCardPosterImageURL = (response, key) => {
	if (response.images && Array.isArray(response.images)) {
		let data = response?.images?.filter((item) => {
			return item.rel.includes(key)
		})
		return data[0]?.href
	} else if (response.card_images) {
		// new PB response
		let data =
			response &&
			response.card_images &&
			response?.card_images?.filter((item) => {
				return item.rel[0] === 'poster' && item.rel[1] === 'MEDIUM'
			})
		return data.length && data[0].href
	} else if (response?.video) {
		//movies or tvshows
		return response?.video?.images?.poster?.MEDIUM
	} else if (response?.program?.images) {
		//tv shows
		return response?.program?.images?.poster?.MEDIUM
	} else if (response?.editorialItemType && !response?.image) {
		return response?.images?.poster?.MEDIUM
	} else if (response?.image?.LARGE) {
		return response?.image?.LARGE
	}
	return null
}

export const transformMenuItems = (menuItems) => {
	let result = []

	menuItems?.forEach((item) => {
		result.push({
			id: item.id || item.name,
			title: item.name || '',
			url: item?.endpoint,
		})
	})
	return result
}

const videoIdDeep = getBy('program.seasons.0.videos.0.id'),
	videoId = getBy('video.id'),
	itemId = getBy('id')
export const getVideoId = (item) => {
	return videoIdDeep(item, videoId(item, itemId(item)))
	//return item?.program?.seasons?.[0]?.videos?.[0]?.id || item?.video?.id || item?.id
}

export const getVideoIdByGenRef = (item, id) => {
	if (item?.video?.id) return item.video.id
	const video = item?.program?.seasons?.[0]?.videos?.find((i) => i.genRef === id)
	return video?.id
}
