import { GET_HOME_ROW, getHomeRowEndpoint } from '@dstv-web-leanback/dstv-frontend-services'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useHomeRowsRefresh = () => {
	const timer = useRef(null)
	const rows = useRef(null)
	const dispatch = useDispatch()

	const homeState = useSelector((state) => state.home)

	useEffect(() => {
		rows.current = homeState.rows
	}, [homeState.rows])

	useEffect(() => {
		handleTimeUpdate(1)

		return () => {
			clearTimeout(timer.current)
		}
	}, [])

	const handleTimeUpdate = (time) => {
		timer.current = setTimeout(() => {
			const staleRows = rows.current.filter((o) => time % o.time_to_refresh_in_seconds === 0)

			staleRows.forEach((row) => {
				dispatch(
					GET_HOME_ROW({
						rowId: row.id,
						url: getHomeRowEndpoint(row),
					})
				)
			})

			handleTimeUpdate(time + 1)
		}, 1000)
	}
}

export default useHomeRowsRefresh
