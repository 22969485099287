import { createSlice } from '@reduxjs/toolkit'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ofType } from 'redux-observable'
import { getEndpointsFromMenu } from '../../utils/storageHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'
import { appendPageNumber } from './helpers'

export const WATCH_LIST_FEATURE_KEY = 'watchlist'

export const initialMylistState = {}
export const watchlistSlice = createSlice({
	name: WATCH_LIST_FEATURE_KEY,
	initialState: {
		data: null,
		loading: true,
	},
	reducers: {
		WATCH_LIST: (state) => {
			state.error = null
			state.loading = true
		},
		WATCH_LIST_PAGING: (state) => {
			state.error = null
		},
		WATCH_LIST_PAGING_SUCCESS: (state, action) => {
			state.loading = false
			if (action.payload?.response?.items.length) {
				state.data.page = action.payload?.response?.page
				state.data.items = [...state.data.items, ...action.payload.response.items]
				state.data?.items?.map((item) => [(item.poster_image = getCardPosterImageURL(item))])
			}
		},
		WATCH_LIST_SUCCESS: (state, action) => {
			state.loading = false
			state.page = action.payload?.response?.page
			state.data = action.payload.data
			state.data?.items?.map((item) => [(item.poster_image = getCardPosterImageURL(item))])
		},
		WATCH_LIST_ERROR: (state, action) => {
			state.data = null
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		GET_GRID_LIST: (state, action) => {
			state.error = null
			state.loading = true
			state.title = action.payload.title
			state.description = action.payload.description
			state.poster_image = action.payload.poster_image
			state.showGrid = action.payload.showGrid
		},
		GET_GRID_LIST_SUCCESS: (state, action) => {
			state.loading = false
			state.gridData = action.payload.data
		},
		GET_GRID_LIST_ERROR: (state, action) => {
			state.gridData = null
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		GET_GRID_ITEM: (state, action) => {
			state.error = null
			state.url = action.payload.url
			state.loading = true
		},
		GET_GRID_ITEM_SUCCESS: (state, action) => {
			state.loading = false
			state.gridItem = action.payload.data
			state.toastId = 'myStuffToast'
			state.toastMessage = 'Removed from My Stuff'
		},
		GET_GRID_ITEM_ERROR: (state, action) => {
			state.gridItem = null
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		REMOVE_ITEM_FROM_GRID_MYSTUFF: (state, error) => {
			state.loading = true
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const watchlistReducer = watchlistSlice.reducer
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const {
	WATCH_LIST,
	WATCH_LIST_PAGING,
	WATCH_LIST_PAGING_SUCCESS,
	WATCH_LIST_CLEAR,
	WATCH_LIST_SUCCESS,
	WATCH_LIST_ERROR,
	GET_GRID_LIST,
	GET_GRID_LIST_SUCCESS,
	GET_GRID_LIST_ERROR,
	GET_GRID_ITEM,
	GET_GRID_ITEM_SUCCESS,
	GET_GRID_ITEM_ERROR,
	REMOVE_ITEM_FROM_GRID_MYSTUFF,
} = watchlistSlice.actions

/*
 * Set up the redux-observable epic
 */
export const watchlistEpic = (action$) =>
	action$.pipe(
		ofType(
			WATCH_LIST.type,
			WATCH_LIST_PAGING.type,
			GET_GRID_LIST.type,
			GET_GRID_ITEM.type,
			REMOVE_ITEM_FROM_GRID_MYSTUFF.type
		),
		mergeMap(watchlistService(action$))
	)
export const watchlistService = (action$) => (action) => {
	switch (action.type) {
		case WATCH_LIST.type: {
			const watchListEndPoint = getEndpointsFromMenu(action.payload.category)
			return HTTP.GET_WITH_CANCEL(
				watchListEndPoint,
				getHeaders(),
				watchlistSuccess,
				watchlistError(action),
				true,
				action$.pipe(ofType(WATCH_LIST))
			)
		}
		case WATCH_LIST_PAGING.type: {
			const watchListEndPoint = getEndpointsFromMenu('mystuff')
			const watchListUrlObj = new URL(watchListEndPoint)
			watchListUrlObj.searchParams.set('page', action.payload.page)
			return HTTP.GET_WITH_CANCEL(
				watchListUrlObj.toString(),
				getHeaders(),
				watchlistPagingSuccess,
				watchlistError(action),
				true,
				action$.pipe(ofType(WATCH_LIST))
			)
		}

		case GET_GRID_LIST.type: {
			const gridListEndPoint = action.payload.url
			return HTTP.GET_WITH_CANCEL(
				gridListEndPoint,
				getHeaders(),
				gridlistSuccess,
				gridlistError(action),
				true,
				action$.pipe(ofType(GET_GRID_LIST))
			)
		}

		case GET_GRID_ITEM.type: {
			return HTTP.GET_WITH_CANCEL(
				appendPageNumber(action.payload.url),
				getHeaders(),
				gridItemSuccess,
				gridItemError(action),
				true,
				action$.pipe(ofType(GET_GRID_ITEM))
			)
		}

		case REMOVE_ITEM_FROM_GRID_MYSTUFF.type: {
			let gridListEndPoint = action.payload.url
			return HTTP.DELETE(gridListEndPoint, null, getHeaders(), getGridItemSuccess(action), gridItemError(action), true)
		}
	}
}

const watchlistSuccess = (response) => {
	return {
		type: WATCH_LIST_SUCCESS.type,
		payload: successResponse(response),
	}
}

const watchlistPagingSuccess = (response) => {
	return {
		type: WATCH_LIST_PAGING_SUCCESS.type,
		payload: successResponse(response),
	}
}

const watchlistError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: WATCH_LIST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const gridlistSuccess = (response) => {
	return {
		type: GET_GRID_LIST_SUCCESS.type,
		payload: successResponse(response),
	}
}

const gridlistError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_GRID_LIST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const gridItemSuccess = (response) => {
	return {
		type: GET_GRID_ITEM_SUCCESS,
		payload: successResponse(response),
	}
}

const getGridItemSuccess = (action) => (response) => {
	return {
		type: GET_GRID_ITEM.type,
		payload: {
			url: action.payload.gridUrl,
		},
	}
}

const gridItemError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_GRID_ITEM_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
